import './initial_requests.scss'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Auth} from "../../types/auth";
import ajax from "../AxiosInterceptor";
import {addUser} from "../../store/userSlice";
import {addOrganisations} from "../../store/organisationSlice";
import {IconeLoader} from "../../components/icone/icone";
import {loadAuthState} from "../localstorage";
import {logIn, logOut} from "../../store/authSlice";
import {createBrowserRouter, Router, RouterProvider} from "react-router-dom";
import {Login} from "../../pages/Login/Login";
import {Accueil} from "../../pages/Accueil/Accueil";
import {Mesures} from "../../pages/Mesures/Mesures";
import {Erreur_404} from "../../pages/404";
import {Capteur_admin} from "../../pages/Capteur_admin/Capteur_admin";
import {Orga_admin} from "../../pages/Orga_admin/Orga_admin";
import {Notifications_tableau} from "../../pages/Notifications/Notifications_tableau";
import {Hubs} from "../../pages/Hubs/Hubs";
import {Inscription_token} from "../../pages/Inscription/Token/Inscription_token";
import {Bureau_etudes} from "../../pages/Bureau_etudes/Bureau_etudes";
import {Mon_compte} from "../../pages/Mon_compte/Mon_compte";
import {Anomalies} from "../../pages/Anomalies/Anomalies";
import ErrorBoundary from "../ErrorBoundary";
import {Logs_cellule_viewer} from "../../pages/logs_viewer/logs_cellule_viewer";
import {Paiements} from "../../pages/Paiements/Paiements";
import {loadInterfaceFromLocalStorage, setInterfaceState} from "../../store/interfaceSlice";
import {Prestataire} from "../../pages/Prestataire/Prestataire";
import {En_direct} from "../../pages/En_direct/En_direct";
import {IconeXmark} from "../../components/icone/solid_react/xmark";
import {Bandeau} from "../../components/bandeau/bandeau";
import {Contacts} from "../../pages/Contacts/Contacts";
import {Inscription} from "../../pages/Inscription/Inscription";
import {Rapports_pdf} from "../../pages/Rapports_pdf/Rapports_pdf";
export function Initial_requests() {
    const [icone, setIcone] = useState(<IconeLoader />)
    const [chargement_texte, setCT] = useState("Connexion au serveur...")
    const auth = useSelector<RootState, Auth>(state => state.auth)
    const dispatch = useDispatch();
    const router = createBrowserRouter([
        {
            path: "/login",
            element: <ErrorBoundary><Login /></ErrorBoundary> ,
        },
        {
            path: "/logout",
            element: <ErrorBoundary><Login /></ErrorBoundary>,
        },
        {
            path: "/inscription/token/:token",
            element: <ErrorBoundary><Inscription_token /></ErrorBoundary>,
        },
        {
            path: "/inscription",
            element: <ErrorBoundary><Inscription /></ErrorBoundary>,
        },
        {
            path: "/",
            element: <ErrorBoundary><Accueil /></ErrorBoundary>,
        },
        {
            path: "/cellule/:celluleId",
            element: <ErrorBoundary><Mesures /></ErrorBoundary>,
        },
        {
            path: "/cellule/:celluleId/historique",
            element: <ErrorBoundary><Logs_cellule_viewer /></ErrorBoundary>,
        },
        {
            path: "/organisation/:orgaId",
            element: <ErrorBoundary><Orga_admin /></ErrorBoundary>,
        },
        {
            path: "/capteur/:capteurId",
            element: <ErrorBoundary><Capteur_admin /></ErrorBoundary>,
        },
        {
            path: "/notifications",
            element: <ErrorBoundary><Notifications_tableau /></ErrorBoundary>,
        },
        {
            path: "/hubs",
            element: <ErrorBoundary><Hubs /></ErrorBoundary>,
        },
        {
            path: "/organisation/:orgaId/anomalies",
            element: <ErrorBoundary><Anomalies /></ErrorBoundary>,
        },
        {
            path: "/bureau",
            element: <ErrorBoundary><Bureau_etudes /></ErrorBoundary>,
        },
        {
            path: "/prestataire",
            element: <ErrorBoundary><Prestataire /></ErrorBoundary>,
        },
        {
            path: "/mon-compte",
            element: <ErrorBoundary><Mon_compte /></ErrorBoundary>,
        },
        {
            path: "/gestion",
            element: <ErrorBoundary><Paiements /></ErrorBoundary>,
        },
        {
            path: "/en-direct",
            element: <ErrorBoundary><En_direct /></ErrorBoundary>,
        },
        {
            path: "/contacts",
            element: <ErrorBoundary><Contacts /></ErrorBoundary>,
        },
        {
            path: "/rapport/:ids",
            element: <ErrorBoundary><Rapports_pdf /></ErrorBoundary>,
        },
        {
            path: "*",
            element: <ErrorBoundary><Erreur_404 /></ErrorBoundary>,
        },
    ]);

    function chemin_hors_login()
    {
        if(window.location.pathname === "/login") return true;
        if(window.location.pathname.includes("/inscription")) return true;

        return false;
    }

    const [routes, setRoutes] = useState(false);

    function queryUserSelf(){
        setCT("Connexion au serveur...")
        ajax.get('/user/getSelf')
            .then(response => {
                dispatch(addUser(response.data));
                queryOrganisations();
            })
            .catch(error => {
                setCT("Erreur de connexion. Merci de ré-essayer ultérieurement.")
                setIcone(<IconeXmark />);
            });
    }
    function queryOrganisations(){
        setCT("Chargement des organisations...")
        ajax.get('/organisations')
            .then(response => {
                dispatch(addOrganisations(response.data));
                setCT("OK")
                setLoaderAsRoutes();
            })
            .catch(error => {
                setCT("Erreur : " + error.message)
                console.log(error);
            });
    }

    useEffect(() => {
        if(chemin_hors_login()) return;
        let local_auth = loadAuthState();
        if (local_auth === undefined || local_auth.token == "")
        {
            window.location.href = "/login";
            return;
        }
        dispatch(logIn(local_auth))

        dispatch(setInterfaceState(loadInterfaceFromLocalStorage()));

    }, [])

    useEffect(() => {
        if(chemin_hors_login()) return;
        if(auth.token === "")
        {
            return;
        }
        queryUserSelf();
    }, [auth])

    function setLoaderAsRoutes()
    {
        setRoutes(true)
    }


    if(chemin_hors_login()) return ( <RouterProvider router={router} /> );


    return (
        <>
            {routes ? (
                <>
                    <RouterProvider router={router} />
                    <Bandeau />
                </>
            ) : (
                <div className={"fs_loading"}>
                    {icone}

                    {chargement_texte}
                </div>
            )}
        </>

    );
}
