import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Organisation} from "../types/organisation";
import {useSelector} from "react-redux";
import env_config from "../env_config";

export type dashboardCollapse = {
    collapse_id: string
    open: boolean
}
export type mesureCelluleAffichage = {
    cellule_id: number
    affichage: string
}
export type vue_En_Direct = {
    nbe_cartes_par_lignes: number
    afficher_conditions: boolean
    mode_compact: boolean
    afficher_zones: boolean
    afficher_notifications: boolean
    organisations: Organisation[]
    zones_a_cache: number[]
    cellules_a_cache: number[]
}
export type interfaceData = {
    dashboardCollapse: dashboardCollapse[]
    mesureCelluleAffichage: mesureCelluleAffichage[]
    mobile_sidebar_show: boolean
    mobile_sidebar_icon: string
    vue_en_direct:vue_En_Direct
}

let initial_state:interfaceData = {
    dashboardCollapse: [],
    mesureCelluleAffichage: [],
    mobile_sidebar_show: false,
    mobile_sidebar_icon: "aucun",
    vue_en_direct: {
        nbe_cartes_par_lignes: 6,
        afficher_conditions: true,
        mode_compact: false,
        afficher_notifications: true,
        afficher_zones: true,
        organisations: [],
        zones_a_cache: [],
        cellules_a_cache: [],
    }
}

export const interfaceSlice = createSlice({
    name: "interface",
    initialState:initial_state as interfaceData,
    reducers: {
        setInterfaceState: (state, action:PayloadAction<interfaceData>) => {
            return action.payload
        },
        setAcceuilCollapseState: (state, action:PayloadAction<dashboardCollapse>) => {
            let existe_deja = false;
            state.dashboardCollapse = state.dashboardCollapse.map(s => {
                if (s.collapse_id === action.payload.collapse_id)
                {
                    existe_deja = true;
                    return action.payload
                }
                return s;
            })

            if (!existe_deja) state.dashboardCollapse.push(action.payload)
            saveInterfaceToLocalStorage(state)
            return state
        },
        setAffichageMesureCelluleState: (state, action:PayloadAction<mesureCelluleAffichage>) => {
            let existe_deja = false;
            state.mesureCelluleAffichage = state.mesureCelluleAffichage.map(s => {
                if (s.cellule_id === action.payload.cellule_id)
                {
                    existe_deja = true;
                    return action.payload
                }
                return s;
            })
            if (!existe_deja) state.mesureCelluleAffichage.push(action.payload)
            saveInterfaceToLocalStorage(state)
            return state
        },
        setMobile_sidebar_show: (state, action:PayloadAction<boolean>) => {
            state.mobile_sidebar_show = action.payload
        },
        setMobile_sidebar_icon: (state, action:PayloadAction<string>) => {
            state.mobile_sidebar_icon = action.payload
        },
        toggleMobile_sidebar_show: (state, action:PayloadAction<void>) => {
            state.mobile_sidebar_show = !state.mobile_sidebar_show
        },
        ouvrirCollapsesSansEcrireDansLocalStorage: (state, action:PayloadAction<void>) => {

            state.dashboardCollapse = state.dashboardCollapse.map(s => {
                s.open = true;
                return s;
            })

        },
        setVueEnDirectSettings: (state, action:PayloadAction<vue_En_Direct>) => {

            state.vue_en_direct = action.payload
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_orga: (state, action:PayloadAction<Organisation>) => {

            state.vue_en_direct.organisations = state.vue_en_direct.organisations.map(o => {
                if (o.id === action.payload.id) return action.payload
                return o;
            })
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_orgas: (state, action:PayloadAction<Organisation[]>) => {

            state.vue_en_direct.organisations = action.payload;
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_zones_add: (state, action:PayloadAction<number>) => {

            state.vue_en_direct.zones_a_cache.push(action.payload)
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_zones_del: (state, action:PayloadAction<number>) => {

            state.vue_en_direct.zones_a_cache = state.vue_en_direct.zones_a_cache.filter(z => z !== action.payload);
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_cellules_add: (state, action:PayloadAction<number>) => {

            state.vue_en_direct.cellules_a_cache.push(action.payload)
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_cellules_del: (state, action:PayloadAction<number>) => {

            state.vue_en_direct.cellules_a_cache = state.vue_en_direct.cellules_a_cache.filter(z => z !== action.payload);
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_nbe_cartes: (state, action:PayloadAction<number>) => {

            state.vue_en_direct.nbe_cartes_par_lignes = action.payload
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_afficher_conditions: (state, action:PayloadAction<boolean>) => {

            state.vue_en_direct.afficher_conditions = action.payload
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_set_mode_compact: (state, action:PayloadAction<boolean>) => {

            state.vue_en_direct.mode_compact = action.payload
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_set_afficher_notification: (state, action:PayloadAction<boolean>) => {

            state.vue_en_direct.afficher_notifications = action.payload
            saveInterfaceToLocalStorage(state)
            return state

        },
        setVueEnDirectSettings_set_afficher_zones: (state, action:PayloadAction<boolean>) => {

            state.vue_en_direct.afficher_zones = action.payload
            saveInterfaceToLocalStorage(state)
            return state

        },
    }
})

export const {setInterfaceState,
                setAcceuilCollapseState,
                setAffichageMesureCelluleState,
                ouvrirCollapsesSansEcrireDansLocalStorage,
                toggleMobile_sidebar_show,
                setMobile_sidebar_icon,
                setMobile_sidebar_show,
                setVueEnDirectSettings,
                setVueEnDirectSettings_nbe_cartes,
                setVueEnDirectSettings_orga,
                setVueEnDirectSettings_orgas,
                setVueEnDirectSettings_afficher_conditions,
                setVueEnDirectSettings_set_mode_compact,
                setVueEnDirectSettings_zones_add,
                setVueEnDirectSettings_zones_del,
                setVueEnDirectSettings_cellules_add,
                setVueEnDirectSettings_cellules_del,
                setVueEnDirectSettings_set_afficher_notification,
                setVueEnDirectSettings_set_afficher_zones
} = interfaceSlice.actions;

export const loadInterfaceFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('interfaceState');
        if (serializedState === null) {
            return initial_state;
        }
        let retour:interfaceData = JSON.parse(serializedState)
        return retour;
    } catch (err) {
        return initial_state;
    }
};

export const saveInterfaceToLocalStorage = (state:interfaceData) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('interfaceState', serializedState);
    } catch {
        // ignore write errors
    }
};
