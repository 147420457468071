import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "../types/user";

const initial_state = {
    id: 0,
    email: "Non connecté",
    roles: [],
    nom_affichage: "Non connecté",
    nom_famille: "Non connecté",
    tel: "Non connecté",
    statut: "Non connecté",
    canaux_resolution_alertes: [],
    timezone: null
};
export const userSlice = createSlice({
    name: "user",
    initialState:initial_state as User,
    reducers: {
        addUser: (state, action:PayloadAction<{message:string, user:User}>) => {
            state = action.payload.user
            return state
        },
        deleteUser: (state, action:PayloadAction<void>) => {
            state = initial_state;
            return state;
        }
    }
})

export const {addUser, deleteUser} = userSlice.actions;