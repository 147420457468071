import { LineChart } from '@mantine/charts';
import React from "react";

import './Rapports_pdf.scss'

import { useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {useParams} from "react-router-dom";



export function Rapports_pdf() {

    const user = useSelector<RootState, User>(state => state.user)

    let param_ids = useParams().ids;
    let ids:string[] = [];

    if (param_ids && param_ids != "")
    {
        ids = param_ids.split(",");
    }

    return (
            <div className={"page-rapport-pdf"}>





                <section>
                    <h1>Titre du document</h1>
                    {"-->"} {JSON.stringify(ids)} {"<--"}
                    <LineChart
                        h={300}
                        data={[
                            {
                                date: 'Mar 22',
                                Apples: 2890,
                                Oranges: 2338,
                                Tomatoes: 2452,
                            },
                            {
                                date: 'Mar 23',
                                Apples: 2756,
                                Oranges: 2103,
                                Tomatoes: 2402,
                            },
                            {
                                date: 'Mar 24',
                                Apples: 3322,
                                Oranges: 986,
                                Tomatoes: 1821,
                            },
                            {
                                date: 'Mar 25',
                                Apples: 3470,
                                Oranges: 2108,
                                Tomatoes: 2809,
                            },
                            {
                                date: 'Mar 26',
                                Apples: 3129,
                                Oranges: 1726,
                                Tomatoes: 2290,
                            },
                        ]}
                        dataKey="date"
                        series={[
                            { name: 'Apples', color: 'indigo.6' },
                            { name: 'Oranges', color: 'blue.6' },
                            { name: 'Tomatoes', color: 'teal.6' },
                        ]}
                        curveType="natural"
                        gridAxis="xy"
                        withDots={false}
                    />
                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                    <table>
                        <tr>
                            <th>ID</th>
                            <th>FR</th>
                            <th>BOOL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Un</td>
                            <td>Oui</td>
                            <td>Donnée plus longue</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Deux</td>
                            <td>Non</td>
                            <td>Encore un peu</td>
                        </tr>
                    </table>

                    <p>Infos en paragraphes après</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eleifend luctus feugiat. Cras congue felis non
                        lacus bibendum pulvinar. Sed porta eros eget ligula fringilla consequat. Quisque sed placerat leo. Quisque vitae
                        ornare est. Curabitur non lacus quis mi sollicitudin congue eget ut ligula. Duis vehicula semper mauris vitae
                        elementum. Ut ultrices orci at ligula ullamcorper, eu gravida elit convallis. In molestie, ex vel mattis
                        fringilla, nulla augue maximus metus, quis blandit dolor est vitae nisi. Vestibulum vestibulum tortor purus, at
                        finibus ligula luctus eu. Curabitur id laoreet nisi. Sed pulvinar ligula vitae ultrices auctor. Vestibulum ut
                        lorem ut nunc ornare vulputate.

                        Suspendisse gravida, risus vitae placerat gravida, odio magna fringilla metus, et iaculis massa est id dui.
                        Suspendisse potenti. Suspendisse felis ante, rutrum vitae nisl a, blandit ultrices elit. Sed nec ornare leo.
                        Nunc a libero vehicula, ornare velit pulvinar, pharetra ligula. Pellentesque aliquet laoreet nisi, a lacinia
                        quam molestie at. Aliquam cursus fringilla odio nec rutrum. Suspendisse convallis lacus sed elementum varius.
                        Quisque auctor nunc sem, et ullamcorper velit commodo quis. Suspendisse tincidunt ligula ligula, ut pretium diam
                        condimentum vel. Maecenas ac lectus tincidunt, ultrices mauris at, fringilla eros. Nulla urna nisl, imperdiet et
                        pretium quis, facilisis vel orci. Nulla facilisi. Vivamus vitae sodales arcu. In pharetra purus elementum, porta
                        neque a, commodo mauris.</p>





                </section>





            </div>

    );
}
