import {Navbar} from "../../components/navbar/navbar";
import {Bandeau_user} from "../../components/sidebar/bandeau_user/bandeau_user";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Organisation} from "../../types/organisation";
import {
    En_direct_organisation,
} from "./En_directl_organisation/En_direct_organisation";
import './En_direct.scss'
import '../Accueil/Accueil.scss'
import ajax from "../../services/AxiosInterceptor";
import {IconeLoader} from "../../components/icone/icone";

import {Notifications} from "../../types/notifications";
import {afficherDateTime, DateTZ} from "../../services/GestionDates";
import {Link, useNavigate} from "react-router-dom";
import env_config from "../../env_config";
import {
    loadInterfaceFromLocalStorage,
    ouvrirCollapsesSansEcrireDansLocalStorage,
    setInterfaceState,
    setMobile_sidebar_icon,
    setVueEnDirectSettings_afficher_conditions,
    setVueEnDirectSettings_nbe_cartes,
    setVueEnDirectSettings_orgas,
    setVueEnDirectSettings_set_afficher_notification, setVueEnDirectSettings_set_afficher_zones,
    setVueEnDirectSettings_set_mode_compact,
    vue_En_Direct
} from "../../store/interfaceSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import {Button, Menu, MultiSelect, Select, Slider, Switch} from "@mantine/core";
import {IconeSliders} from "../../components/icone/solid_react/sliders";
import {IconeCircle} from "../../components/icone/solid_react/circle";
import {IconeArrowLeft} from "../../components/icone/solid_react/arrow-left";
import {IconeBells} from "../../components/icone/solid_react/bells";
import {IconeBell} from "../../components/icone/solid_react/bell";
import {IconeBullhorn} from "../../components/icone/solid_react/bullhorn";
import {IconeInfo} from "../../components/icone/solid_react/info";
import {IconeHome} from "../../components/icone/solid_react/home";

export function En_direct() {

    const all_organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)
    const vue_en_direct_settings = useSelector<RootState, vue_En_Direct>(state => state.interface.vue_en_direct)

    const [recherche, setRecherche] = useState<string>("")

    const [notifications, setNotifications] = useState<Notifications[]|null>(null)

    const navigate = useNavigate();

    const [last_orgas, setLast_orgas] = useState<Organisation[]>([]);

    const [afficherReglages, setAfficherReglages] = useState(false);

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("bell"))

    useEffect(() => {
        dispatch(setInterfaceState(loadInterfaceFromLocalStorage()));

    }, [])

    useEffect(() => {
        if (recherche_min() !== "")
        {
            dispatch(ouvrirCollapsesSansEcrireDansLocalStorage());
        }
        else {
            dispatch(setInterfaceState(loadInterfaceFromLocalStorage()));
        }
    }, [recherche])

    useEffect(() => {
        setLast_orgas(vue_en_direct_settings.organisations);
        dispatch(setVueEnDirectSettings_orgas(all_organisations.filter(ao => vue_en_direct_settings.organisations.filter(o => o.id === ao.id).length > 0)))

        // setOrganisations(all_organisations.filter(ao => organisations.filter(o =>o.id === ao.id).length > 0))
    }, [all_organisations]);

    useEffect(() => {

        if (vue_en_direct_settings.organisations.length === 0) setAfficherReglages(true)

        if (last_orgas.map(o => o.id).toString() !== vue_en_direct_settings.organisations.map(o => o.id).toString()) setNotifications(null)
        query_notifications();
        const interval_clear = setInterval(() => {
            query_notifications();
        }, env_config.refresh_rate);

        return () => {
            clearInterval(interval_clear);
        }
    }, [vue_en_direct_settings.organisations]);

    function recherche_min()
    {
        if (recherche.length > 2) return recherche
        else return ""
    }

    function query_notifications()
    {
        if (vue_en_direct_settings.organisations.length > 0)
        {
            ajax.get("/utilisateur/dashboard_notifications/" + vue_en_direct_settings.organisations.map(o => o.id).toString()).then(response => {
                console.log("En_direct - Refresh des notifications OK ; orga : " + vue_en_direct_settings.organisations.map(o => o.nom).toString())
                setNotifications(response.data.notifications)
            }).catch(error => {})
        }
    }

    const steps_slider_nbe_cartes_mesures_affichage = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
    ];


    return (
        <div className={"app"}>
            <div className={"content page-en-direct" + (vue_en_direct_settings.afficher_notifications ? "" : " en_direct_fullscreen")}>
                <div className={"organisations"}>


                    <div className={"bandeau_user en-direct-texte"} style={{justifyContent: "space-between", width: "100%", padding: "unset"}}>

                        <div className={"flex-row"}>
                            <IconeCircle/>
                            En direct
                        </div>

                        <h2 className={"hide_on_mobile"}>
                            {/*<Icone nom={"caret-right"} className={open ? "rotate" : ""}/>*/}
                            {vue_en_direct_settings.organisations.map(o => o.nom).toString().length > 50 ? "Mes organisations" : vue_en_direct_settings.organisations.map(o => o.nom).toString().replaceAll(",", ", ")}
                        </h2>

                        <div className={"en-ligne"}>
                            <button className={"sec"} onClick={() => navigate("/")}><IconeHome /> Accueil </button>

                            <button className={"sec"} onClick={() => {setAfficherReglages(!afficherReglages)}}><IconeSliders/> {afficherReglages ? "Fermer les réglages" : "Réglages"} </button>
                        </div>

                    </div>

                    {afficherReglages && (
                        <div className={"en-direct-reglages"}>
                            <h3><IconeSliders/> Réglages de la vue En Direct </h3>
                            <p>Organisation à afficher : </p>
                            <MultiSelect
                                // defaultValue={all_organisations[0] ? all_organisations[0].id+"" : null}
                                data={all_organisations.map(o => {
                                    return {value: o.id + "", label:o.nom}
                                } )}
                                value={vue_en_direct_settings.organisations.map(o => o.id + "")}
                                onChange={(e) => {
                                    dispatch(setVueEnDirectSettings_orgas(all_organisations.filter(ao => e.includes(ao.id+""))))}}
                                searchable

                            />
                            <p>Nombre de cartes à afficher par ligne : </p>
                            <Slider
                                value={vue_en_direct_settings.nbe_cartes_par_lignes}
                                onChange={(e) => {
                                    dispatch(setVueEnDirectSettings_nbe_cartes(e))
                                }}
                                min={1}
                                max={10}
                                label={(val) => steps_slider_nbe_cartes_mesures_affichage.find((mark) => mark.value === val)!.label}
                                step={1}
                                marks={steps_slider_nbe_cartes_mesures_affichage}
                                styles={{ markLabel: { display: 'none' } }}
                            />
                            <Switch
                                checked={vue_en_direct_settings.afficher_conditions}
                                onChange={(e) => {
                                    dispatch(setVueEnDirectSettings_afficher_conditions(e.target.checked))
                                }}
                                label={"Afficher les infos supplémentaires sur les cartes ?"}
                            />
                            <Switch
                                checked={vue_en_direct_settings.mode_compact}
                                onChange={(e) => {
                                    dispatch(setVueEnDirectSettings_set_mode_compact(e.target.checked))
                                }}
                                label={"Affichage compact ?"}
                            />
                            <Switch
                                checked={vue_en_direct_settings.afficher_zones}
                                onChange={(e) => {
                                    dispatch(setVueEnDirectSettings_set_afficher_zones(e.target.checked))
                                }}
                                label={"Afficher les zones ?"}
                            />
                            <Switch
                                checked={vue_en_direct_settings.afficher_notifications}
                                onChange={(e) => {
                                    dispatch(setVueEnDirectSettings_set_afficher_notification(e.target.checked))
                                }}
                                label={"Afficher les notifications ?"}
                                className={"hide_on_mobile"}
                            />
                        </div>
                    )}



                    {vue_en_direct_settings.organisations.length > 0 ? "" : "Aucune organisation sélectionnée..."}

                    {vue_en_direct_settings.organisations.map(orga => (
                        <>

                            {/*<LazyLoad height={300} offset={300} key={organisation.id}>*/}
                            <En_direct_organisation organisation={orga} key={orga.id} recherche={recherche_min()} parametre_ouvert={afficherReglages}/>
                            {/*</LazyLoad>*/}
                        </>

                    ))

                    }
                </div>
            </div>

            {vue_en_direct_settings.afficher_notifications && (
                <Sidebar hideOnMobile={true}>
                    {/*<div className={"bandeau_user en-direct-texte"} style={{justifyContent: "space-between"}}>*/}
                    {/*    <div className={"flex-row"}>*/}
                    {/*        <Icone nom={"circle"}/>*/}
                    {/*        En direct*/}
                    {/*    </div>*/}

                    {/*    <Menu position="bottom-end">*/}
                    {/*        <Menu.Target>*/}
                    {/*            <button className={"sec"}>Réglages <Icone nom={"gear"}/></button>*/}
                    {/*        </Menu.Target>*/}

                    {/*        <Menu.Dropdown>*/}
                    {/*            /!*<Menu.Label>Application</Menu.Label>*!/*/}
                    {/*            <Menu.Item icon={<Icone nom={"user"}/>}><Link to="/mon-compte">Mon compte</Link></Menu.Item>*/}
                    {/*            <Menu.Item color="red" icon={<Icone nom={"right-from-bracket"}/>}>*/}
                    {/*                <Link to="/logout">Déconnexion</Link>*/}
                    {/*            </Menu.Item>*/}

                    {/*            /!*<Menu.Divider />*!/*/}

                    {/*            /!*<Menu.Label>Danger zone</Menu.Label>*!/*/}
                    {/*            /!*<Menu.Item icon={<Icone nom={"gear"} />}>Transfer my data</Menu.Item>*!/*/}
                    {/*        </Menu.Dropdown>*/}
                    {/*    </Menu>*/}

                    {/*</div>*/}

                    {notifications ? (
                        <div className={"notifications"} style={{marginTop: "0"}}>
                            <h3><IconeBell/> Notifications </h3>
                            {notifications.map(notification => {
                                return (
                                    <div className={
                                        (notification.type === "Global" ? "carte_notification jaune" : "carte_notification red")
                                        + (notification.cellule && " clickable")
                                    }
                                         key={notification.id} onClick={() => {
                                        if (notification.cellule)
                                        {
                                            navigate("/cellule/" + notification.cellule.id + "?dt=" + notification.envoi_heure)
                                        }
                                    }}>
                                        <div className={"titre"}>
                                            <div style={{fontWeight: "bold"}}>
                                                {notification.type === "Anomalie-ntf"
                                                    && <div className={"notif-en-tete"}><IconeBell />{notification.cellule?.nom}</div> }
                                                {notification.type === "Resolution-ntf"
                                                    && <div className={"notif-en-tete resolution"}><IconeBell />{notification.cellule?.nom}</div> }
                                                {notification.type === "Global"
                                                    && <div className={"notif-en-tete"}><IconeBullhorn />{"Information"}</div> }
                                            </div>
                                            <i>{afficherDateTime(new DateTZ(notification.envoi_heure))}</i>
                                        </div>
                                        <p className={"infos"}>{notification.cellule && "↪"} {notification.message}</p>
                                    </div>
                                )
                            })}

                            {notifications.length === 0 && (
                                <p>Aucune notification</p>
                            )}

                        </div>
                    ) : (
                        <>
                            {vue_en_direct_settings.organisations.length > 0 ? (
                                <>
                                    &nbsp;<br />
                                    <div className={"en-ligne"} style={{justifyContent: "center"}}><IconeLoader />Chargement...</div>
                                    &nbsp;<br />
                                </>
                            ) : (
                                <>
                                    &nbsp;<br />
                                    <div className={"en-ligne"} style={{justifyContent: "center"}}>Merci d'indiquer une organisation.</div>
                                    &nbsp;<br />
                                </>
                            )}
                        </>

                    )}



                </Sidebar>
            )}


        </div>

    );
}
