import {store} from "../store/store";

export function permissionIsGranted(organisation_id:number|undefined, permission: string)
{
    if (permission === "ADMIN")
    {
        return store.getState().user.roles.includes("ROLE_ADMIN")
    }

    if (typeof organisation_id === "undefined") return false

    let orga = store.getState().organisation.list.filter(o => o.id === organisation_id)[0]
    if (typeof orga === "undefined" || typeof orga.permissions === "undefined") return false

    return orga.permissions.includes(permission);
}