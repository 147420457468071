import React, {useEffect, useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import './navbar.scss'
import logo_freshtime from './logo_freshtime.svg';
import {Icone} from "../icone/icone";
import {useDispatch, useSelector} from "react-redux";
import {deleteUser} from "../../store/userSlice";
import {logOut} from "../../store/authSlice";
import {unsetCapteurModale} from "../../store/capteur_modale_Slice";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {clearOrganisations} from "../../store/organisationSlice";
import {useDisclosure} from "@mantine/hooks";
import {Burger, Button, FocusTrap, Menu, rem, TextInput} from "@mantine/core";
import {Simulate} from "react-dom/test-utils";
import toggle = Simulate.toggle;
import {toggleMobile_sidebar_show} from "../../store/interfaceSlice";
import {IconeHouse} from "../icone/solid_react/house";
import {IconeSatelliteDish} from "../icone/solid_react/satellite-dish";
import {IconeEnvelope} from "../icone/solid_react/envelope";
import {IconeSignalStream} from "../icone/solid_react/signal-stream";
import {IconeEuroSign} from "../icone/solid_react/euro-sign";
import {IconeFlask} from "../icone/solid_react/flask";
import {IconeReceipt} from "../icone/solid_react/receipt";
import {IconeSliders} from "../icone/solid_react/sliders";
import {IconeCircleQuestion} from "../icone/solid_react/circle-question";
import {IconeUser} from "../icone/solid_react/user";
import {IconeRightFromBracket} from "../icone/solid_react/right-from-bracket";
import {IconeBell} from "../icone/solid_react/bell";
import {IconePenToSquare} from "../icone/solid_react/pen-to-square";
import {IconeWindWarning} from "../icone/solid_react/wind-warning";
import {IconeCircleExclamation} from "../icone/solid_react/circle-exclamation";
import {Organisation} from "../../types/organisation";
import env_config from "../../env_config";
import {IconeAddressBook} from "../icone/solid_react/address-book";
import {IconeNarwhal} from "../icone/solid_react/narwhal";
import {IconeStore} from "../icone/solid_react/store";
import {texte_est_inclus} from "../../services/GestionTextes";
export function Navbar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector<RootState, User>(state => state.user)
    const [hideNavItems, setHideNavItems] = useState(true);
    const [burger_opened, burger_handlers] = useDisclosure(false);
    const mobile_sidebar_show = useSelector<RootState, boolean>(state => state.interface.mobile_sidebar_show)
    const mobile_sidebar_icon = useSelector<RootState, string>(state => state.interface.mobile_sidebar_icon)
    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [menu_orgas_recherche, setMenu_orgas_recherche] = useState("");


    const orga_nouvelle_paiement_demain = useSelector<RootState, Organisation[]>(state => state.organisation.list)
    function checkSiDoitAfficherMarqueurPaiementDemain()
    {
        return orga_nouvelle_paiement_demain.filter(o => {
            return o.etat === "New" && o.prochainPrelevement && o.balance <= 0
        }).length > 0;

    }

    useEffect(() => {
            setTimeout(() => {
                setHideNavItems(!burger_opened)
            }, 500)
    }, [burger_opened])

    return (
        <>
            <div className={"mobile_header"}>
                <Burger opened={burger_opened}
                        onClick={() => {
                            if (!burger_opened)
                            {
                                setHideNavItems(false)
                                setTimeout(() => {
                                    burger_handlers.toggle()
                                }, 20)
                            }
                        }}
                        aria-label={burger_opened ? 'Fermer navigation' : 'Ouvrir navigation'}
                        color="#fff" size={18}
                />

                <Link to="/" onClick={() => {dispatch(unsetCapteurModale())}} className="logo-link"><img src={logo_freshtime} className={"logo"} alt=""/></Link>


                {mobile_sidebar_icon !== "aucun" ? (
                    <div className={"mobile_trigger_notifications" + (mobile_sidebar_show ? " open" : "")} onClick={() => {dispatch(toggleMobile_sidebar_show())}}>
                        {/*{location.pathname === "/" ? (*/}
                        {/*    <Icone nom={"bell"} />*/}
                        {/*) : (*/}
                        {/*    <Icone nom={"pen-to-square"} />*/}
                        {/*)}*/}
                        {mobile_sidebar_icon === "bell" || mobile_sidebar_icon === "pen-to-square" ? (
                            <>
                                {mobile_sidebar_icon === "bell" && <IconeBell />}
                                {mobile_sidebar_icon === "pen-to-square" && <IconePenToSquare />}
                            </>
                        ) : (
                            <Icone nom={mobile_sidebar_icon} />
                        )}

                    </div>
                ) : (<div className={"mobile_trigger_notifications"}> <Icone nom={"aucun"} /> </div>)}


                <div className={"nav_background" + (burger_opened ? " open" : "") + (hideNavItems ? " hide" : "")} onClick={(e) => {
                    e.stopPropagation();
                    burger_handlers.close();
                }}></div>

            </div>
            <nav className={(burger_opened ? " open" : "") + (hideNavItems ? " hide" : "")}>
                <Link to="/" onClick={() => {dispatch(unsetCapteurModale())}} className="logo-link"><img src={logo_freshtime} className={"logo"} alt=""/></Link>

                <ul>
                    <li className={location.pathname === "/" ? "selected" : ""}>
                        <Link to="/" onClick={() => {dispatch(unsetCapteurModale())}}><IconeHouse /> Accueil</Link>
                    </li>
                    <li className={false ? "selected" : ""} id={"nav_organisations"}>
                        <Menu position="right-start" offset={6}
                              withArrow arrowPosition="center"
                              trigger="hover" openDelay={100} closeDelay={200}
                              closeOnItemClick={false}
                              portalProps={{
                                  className: "orgas_nav"
                              }}
                        >
                            <Menu.Target>
                                <a><IconeStore />Organisations</a>
                            </Menu.Target>

                            <Menu.Dropdown>
                                {organisations.length > 10 && (
                                    <Menu.Item>
                                            <TextInput
                                                placeholder={"Recherche..."}
                                                value={menu_orgas_recherche}
                                                onChange={e => setMenu_orgas_recherche(e.target.value)}
                                            />
                                    </Menu.Item>
                                )}

                                {organisations.filter(o => texte_est_inclus(o.nom, menu_orgas_recherche) || o.id+"" === menu_orgas_recherche).slice(0, 16).map(orga => (
                                    <Menu.Item closeMenuOnClick={true}>
                                        <Link to={"/organisation/" + orga.id} style={{width: "100%"}}>
                                            {orga.nom}
                                        </Link>
                                    </Menu.Item>
                                ))}

                            </Menu.Dropdown>
                        </Menu>
                    </li>
                    <li className={location.pathname === "/hubs" ? "selected" : ""}>
                        <Link to="/hubs"><IconeSatelliteDish />Hubs</Link>
                    </li>
                    <li className={location.pathname === "/notifications" ? "selected" : ""}>
                        <Link to="/notifications"><IconeEnvelope />Notifications</Link>
                    </li>
                    <li className={location.pathname === "/en-direct" ? "selected" : ""}>
                        <Link to="/en-direct"><IconeSignalStream />En direct</Link>
                    </li>
                    <li className={location.pathname === "/gestion" ? "selected" : ""}>
                        <Link to="/gestion"><IconeEuroSign />
                            Paiements
                            {checkSiDoitAfficherMarqueurPaiementDemain() && (
                                <IconeCircleExclamation className={"red"} />
                            )}

                        </Link>
                    </li>
                </ul>
                <div className={"separator"}></div>
                <ul>
                    {user.roles.includes("ROLE_ADMIN") && (
                        <li className={location.pathname === "/bureau" ? "selected" : ""}>
                            <Link to="/bureau"><IconeFlask />Bureau d'étude</Link>
                        </li>
                    )}
                    {(user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_PRESTATAIRE")) && (
                        <li className={location.pathname === "/prestataire" ? "selected" : ""}>
                            <Link to="/prestataire"><IconeReceipt />Prestataire</Link>
                        </li>
                    )}


                </ul>
                <div className={"separator"}></div>
                <ul>
                    <li className={location.pathname === "/contacts" ? "selected" : ""}>
                        <Link to="/contacts"><IconeAddressBook />Contacts</Link>
                    </li>
                    {/*<li className={location.pathname === "/parametres" ? "selected" : ""}>*/}
                    {/*    <Link to="/parametres"><IconeSliders />Paramètres</Link>*/}
                    {/*</li>*/}
                    <li className={location.pathname === "/assistance" ? "selected" : ""}>
                        <Link to="/assistance"><IconeCircleQuestion />Documentation</Link>
                    </li>
                    <li className={(location.pathname === "/mon-compte" ? "selected" : "")}>
                        <Link to="/mon-compte"><IconeUser />Mon compte</Link>
                    </li>
                    <li className={"only_mobile"}>
                        <Link to="/logout"><IconeRightFromBracket />Déconnexion</Link>
                    </li>
                </ul>
            </nav>
        </>

    );
}
