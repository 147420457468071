import {Cellule} from "../../../types/cellule";
import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Modal, Paper, Text, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {IconeTag} from "../../../components/icone/solid_react/tag";
import {IconeChartLine} from "../../../components/icone/solid_react/chart-line";
import "./modale_graphique_export.scss"
import "../../En_direct/En_direct.scss"
import {DateTimePicker} from "@mantine/dates";
import {Mesure} from "../../../types/mesure";
import {
    afficherDate,
    afficherDateTime,
    afficherHeure,
    DateTZ
} from "../../../services/GestionDates";
import {Carte_mesure} from "../../../components/carte_mesure/carte_mesure";
import {BarChart, LineChart} from "@mantine/charts";
import {debug} from "util";
import {IconeFileExcel} from "../../../components/icone/solid_react/file-excel";

export function Modale_graphique_export({
                                       cellule,
                                       setCellule
                                   }: { cellule: Cellule, setCellule: React.Dispatch<Cellule | null> }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    let hier = new DateTZ();
    hier.setHours(hier.getHours() - 24);
    const [date_debut, setDate_debut] = useState<DateTZ>(hier);
    const [date_fin, setDate_fin] = useState<DateTZ>(new DateTZ());

    const [mesures, setMesures] = useState(<></>);

    const [mesures_data, setMesures_data] = useState<Mesure[]>([]);

    function load_mesures(cellule_id:number, ajouter?:boolean)
    {
        set_btn_loading(true);
        ajaxMesures(cellule_id)
            .then(data => {
                set_btn_loading(false);
                if (ajouter) {
                    // let all_mesures:Mesure[] = [...mesures_data, ...data.mesures]
                    // let new_mesures:Mesure[] = [];
                    // JSON.parse(JSON.stringify(all_mesures)).map((m:Mesure) => {
                    //     let heure = new DateTZ(m.enregistrement);
                    //     heure.setMinutes(Math.round(heure.getMinutes()/10)*10);
                    //     heure.setSeconds(0);
                    //
                    //     all_mesures = all_mesures.filter((al) => {
                    //         let heure_al = new DateTZ(al.enregistrement);
                    //         heure_al.setMinutes(Math.round(heure_al.getMinutes()/10)*10);
                    //         heure_al.setSeconds(0);
                    //         if (heure_al.getTime() === heure.getTime())
                    //         {
                    //             //La mesure al est à la meme dizaine de minute
                    //
                    //         }
                    //     })
                    // })

                    setMesures_data(mesures_data.concat(data.mesures).sort((a, b) => {
                        if (a.timestamp === b.timestamp) return 0;
                        if (a.timestamp < b.timestamp) return -1;
                        else return 1
                    }))
                }
                else setMesures_data(data.mesures)

            })
    }

    useEffect(() => {

        if ("graphique" === "graphique") {
            let mesure_min: Mesure | null = null;
            let mesure_max: Mesure | null = null;
            let mesure_moy: number = 0;
            let mesures: Mesure[] = JSON.parse(JSON.stringify(mesures_data));
            let mesures_triees: Mesure[] = mesures_data.sort((a: Mesure, b: Mesure) => {
                if (Number(a.mesure) < Number(b.mesure)) return -1;
                if (Number(a.mesure) > Number(b.mesure)) return 1;
                return 0;
            })
            mesures = mesures.filter(m => !isNaN(Number(m.mesure)))
            mesures_triees = mesures_triees.filter(m => !isNaN(Number(m.mesure)))

            if (mesures.length === 0) {
                setMesures(<>Aucune mesure disponible pour analyse.</>)
                return;
            }

            mesures.map((mesure: Mesure) => { //.filter((m:Mesure) => !m.mesure.includes("!"))
                // if (mesure_min === null || Number(mesure_min.mesure) > Number(mesure.mesure)) mesure_min = mesure;
                // if (mesure_max === null || Number(mesure_max.mesure) < Number(mesure.mesure)) mesure_max = mesure;
                console.log("MESURE : " + mesure.mesure)
                mesure_moy += Number(mesure.mesure);
                console.log("mesure_moy : " + mesure_moy)
            })
            mesure_moy = Math.round(mesure_moy / mesures.length * 100) / 100;

            let mesure_ecart_type: number = 0;
            mesures.map((mesure: Mesure) => {
                mesure_ecart_type += Math.pow(Number(mesure.mesure) - mesure_moy, 2);
            })
            mesure_ecart_type = Math.round(Math.sqrt(mesure_ecart_type / mesures.length) * 100) / 100;


            let mesure_med: Mesure = mesures_triees[Math.round((mesures_triees.length - 1) / 2)];
            let calc_centile = (centile: number) => {
                let mes = mesures_triees[Math.round((mesures_triees.length - 1) / 100 * centile)];
                return (
                    <Carte_mesure
                        titre={centile + "e centile"}
                        texte={mes ? mes.mesure : "Aucune"}
                        sonde={cellule?.sonde_type}
                        handleClick={() => {
                            alert("Le " + centile + "e centile indique la mesure réelle qui est à " + centile + "% des mesures triées. " +
                                "Autrement dit, " + centile + "% des mesures sont en dessous de " + mes.mesure + cellule?.sonde_type?.affichage_suffix + " et " + (100 - centile) + "% sont au dessus.");
                        }}
                        key={centile + "e"}
                        conditions={cellule?.statut_actuel?.statut.can_use_alertes ? cellule?.conditions : []}
                    />
                )
            }
            let nbe_mesures: number = mesures_triees.length;
            mesure_min = mesures_triees[0];
            mesure_max = mesures_triees[mesures_triees.length - 1];

            let mesures_tableau_bar: { temp: number, compt: number, centile: number }[] = [];
            mesures_triees.map((m, index) => {
                let existe = 0
                let centile = Math.round((index) * 100 / mesures_triees.length * 100) / 100
                //On récupère le compte et on retire du tableau la mesure :
                mesures_tableau_bar = mesures_tableau_bar.filter(t => {
                    if (t.temp === Math.round(Number(m.mesure) * 10) / 10) {
                        existe = t.compt;
                        centile = (t.centile + centile) /2;
                        return false
                    }
                    return true;
                })
                //On ajoute au tableau :
                mesures_tableau_bar.push({
                    temp: Math.round(Number(m.mesure) * 10) / 10,
                    compt: existe + 1,
                    centile: centile,
                })
            })
            for (let i = Number(mesure_min.mesure); i < Number(mesure_max.mesure); i = Math.round((i + 0.1) * 10) / 10) {
                let existe = 0
                //On récupère le compte et on retire du tableau la mesure :
                if (!mesures_tableau_bar.find(t => Number(t.temp) === i)) {
                    //On ajoute au tableau :
                    mesures_tableau_bar.push({
                        temp: i,
                        compt: 0,
                        centile: 0,
                    })
                }
            }
            mesures_tableau_bar = mesures_tableau_bar.sort((a, b) => {
                if (Number(a.temp) < Number(b.temp)) return -1;
                if (Number(a.temp) > Number(b.temp)) return 1;
                return 0;
            })

            let mesures_tableau_line:Mesure[] = [];
            mesures_tableau_line.push(mesures[0])
            for (let i = 1; i < mesures.length; i++) {
                let heure = new DateTZ(mesures[i-1].enregistrement)
                heure.setMinutes(heure.getMinutes() + 20)
                while(heure.getTime() < new DateTZ(mesures[i].enregistrement).getTime())
                {
                    mesures_tableau_line.push(
                        {
                            cellule_id: cellule.id,
                            // @ts-ignore
                            mesure: null,
                            enregistrement: heure.toISOString(),
                            timestamp: 0,
                            statut: null
                        })
                    heure.setMinutes(heure.getMinutes() + 20)
                }

                mesures_tableau_line.push(mesures[i])
            }

            let cellules:number[] = []
            mesures_tableau_line.map(m => {
                if (!cellules.includes(m.cellule_id)) cellules.push(m.cellule_id)
            })

            setMesures(
                <>
                    <Carte_mesure
                        titre={"Nombre de mesures"}
                        texte={nbe_mesures ? nbe_mesures + '' : "Aucune"}
                        sonde={null}
                        handleClick={() => {
                            alert("Nombre de mesures sur la période");
                        }}
                        key={"nbe"}
                        conditions={undefined}
                    />
                    <Carte_mesure
                        titre={"Minimum"}
                        texte={mesure_min ? mesure_min.mesure : "Aucune"}
                        sonde={cellule?.sonde_type}
                        handleClick={() => {
                            // if (mesure_min) navigateToMesure(mesure_min)
                        }}
                        key={"min"}
                        conditions={cellule?.statut_actuel?.statut.can_use_alertes ? cellule?.conditions : []}
                    />
                    <Carte_mesure
                        titre={"Maximum"}
                        texte={mesure_max ? mesure_max.mesure : "Aucune"}
                        sonde={cellule?.sonde_type}
                        handleClick={() => {
                            // if (mesure_max) navigateToMesure(mesure_max)
                        }}
                        key={"max"}
                        conditions={cellule?.statut_actuel?.statut.can_use_alertes ? cellule?.conditions : []}
                    />
                    <Carte_mesure
                        titre={"Moyenne"}
                        texte={!isNaN(mesure_moy) ? mesure_moy + "" : "Aucune"}
                        sonde={!isNaN(mesure_moy) ? cellule?.sonde_type : null}
                        handleClick={() => {
                            alert("La moyenne est calculé et ne représente donc pas une mesure réelle.");
                        }}
                        key={"moy"}
                        conditions={cellule?.statut_actuel?.statut.can_use_alertes ? cellule?.conditions : []}
                    />
                    <Carte_mesure
                        titre={"Ecart type"}
                        texte={!isNaN(mesure_ecart_type) ? mesure_ecart_type + "" : "Aucune"}
                        sonde={!isNaN(mesure_ecart_type) ? cellule?.sonde_type : null}
                        handleClick={() => {
                            alert("L'écart-type mesure à quel point les valeurs d'un ensemble de données sont éloignées de la moyenne : plus il est grand, plus les données sont dispersées ; " +
                                "plus il est petit, plus elles sont proches les unes des autres.\n\n" +
                                "Dis autrement, plus la valeur est basse, plus la " + cellule?.sonde_type?.affichage_nom_mesure.toLowerCase() + " est restée stable et proche de la moyenne sur la période. ");
                        }}
                        key={"ecart_type"}
                        conditions={undefined}
                    />
                    {/*<Carte_mesure*/}
                    {/*    titre={"Médiane"}*/}
                    {/*    texte={mesure_med ? mesure_med.mesure : "Aucune"}*/}
                    {/*    sonde={cellule?.sonde_type}*/}
                    {/*    handleClick={() => {*/}
                    {/*        alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +*/}
                    {/*            "Autrement dit, 50% des mesures sont en dessous de " + mesure_med.mesure + cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");*/}
                    {/*    }}*/}
                    {/*    key={"med"}*/}
                    {/*    conditions={cellule?.statut_actuel?.statut.can_use_alertes ? cellule?.conditions : []}*/}
                    {/*/>*/}
                    {[5, 95].map(centille => calc_centile(centille))}
                    {/*{[75, 90, 95].map(centille => calc_centile(centille))}*/}


                    <h1><br />{cellule?.sonde_type?.affichage_nom_mesure} sur la période : </h1>
                    <LineChart
                        h={300}
                        data={
                            mesures_tableau_line.map(mesure => {
                                let data = {
                                    date: afficherDateTime(mesure.enregistrement),
                                    // temp: mesure.mesure ?? undefined,
                                    // Min: cellule?.conditions.find(c => c.slug === 0)?.valeur_a_comparer ?? null,
                                    // Max: cellule?.conditions.find(c => c.slug === 1)?.valeur_a_comparer ?? null
                                }
                                // @ts-ignore
                                data[mesure.cellule_id] = mesure.mesure ?? undefined
                                return data;
                            })
                        }
                        yAxisProps={{
                            domain: [
                                // Math.floor(Math.min(Number(mesure_min.mesure), Number(cellule?.conditions.find(c => c.slug === 0)?.valeur_a_comparer ?? 99999)) - Math.abs(mesure_ecart_type / 2)),
                                // Math.ceil(Math.max(Number(mesure_max.mesure), Number(cellule?.conditions.find(c => c.slug === 1)?.valeur_a_comparer ?? -99999)) + Math.abs(mesure_ecart_type / 2))
                                Math.floor(Number(mesure_min.mesure) - Math.abs(mesure_ecart_type / 2)),
                                Math.ceil(Number(mesure_max.mesure) + Math.abs(mesure_ecart_type / 2))
                            ]
                        }}
                        dataKey="date"
                        valueFormatter={value => value + (cellule?.sonde_type?.affichage_suffix ?? "")}
                        w={"100%"}
                        referenceLines={[
                            {
                                y: cellule?.conditions.find(c => c.slug === 1)?.valeur_a_comparer ?? undefined,
                                label: 'Max',
                                color: 'red.6'
                            },
                            {
                                y: cellule?.conditions.find(c => c.slug === 0)?.valeur_a_comparer ?? undefined,
                                label: 'Min',
                                color: 'red.6'
                            },
                        ]}
                        series={
                        // [
                        //     // { name: 'Max', color: 'red.6', strokeDasharray: '5 5' },
                        //     {
                        //         name: 'temp',
                        //         color: 'blue.6',
                        //         label: cellule?.sonde_type?.affichage_nom_mesure
                        //     },
                        //     // { name: 'Min', color: 'red.6', strokeDasharray: '5 5' },
                        // ]
                            cellules.map((c, index) => {
                                let color = "blue.6"
                                switch (index){
                                    case 1:
                                        color = "red.6";
                                        break;
                                    case 2:
                                        color = "cyan.6";
                                        break;
                                    case 3:
                                        color = "yellow.6";
                                        break;
                                    case 4:
                                        color = "purple.6";
                                        break;
                                }
                                return {
                                    name: c + "",
                                    color: color,
                                    label: cellule?.sonde_type?.affichage_nom_mesure
                                }
                            })
                    }
                        curveType="natural"
                        withDots={false}
                        connectNulls={false}
                    />




                    <h1><br />Répartitions des mesures : </h1>
                    <BarChart
                        h={300}
                        withYAxis={false}
                        gridAxis="none"
                        data={
                            mesures_tableau_bar.map((m, index) => {
                                return {
                                    temp: m.temp + (cellule?.sonde_type?.affichage_suffix ?? ""),
                                    compt: m.compt,
                                    // compt: m.compt + ", " + Math.ceil(index * 100 / mesures_tableau_bar.length) + "e centile",
                                    centile: m.centile,
                                    color: (Number(m.temp) > Number(cellule?.conditions.find(c => c.slug === 0)?.valeur_a_comparer ?? -999999)
                                        && Number(m.temp) < Number(cellule?.conditions.find(c => c.slug === 1)?.valeur_a_comparer ?? 999999)) ? "blue.6" : "red.6"
                                }
                            })
                        }
                        dataKey="temp"
                        w={"100%"}
                        barProps={{radius: 10}}
                        referenceLines={[5, 25, 50, 75, 95].map(i => ({
                            x: Math.round(Number(
                                mesures_triees[
                                    Math.max(0, Math.round((mesures_triees.length) / 100 * i)-1)
                                    ].mesure
                            )*10)/10 + "°C",
                            label: i === 50 ? "Médiane" : i + 'e',
                            color: 'gray.6',
                            labelPosition: 'insideTopRight',
                        }))}
                        tooltipProps={{
                            // @ts-ignore
                            content: ({label, payload}: {
                                label: string;
                                payload: Record<string, any>[] | undefined;
                            }) => {
                                if (!payload) return null;

                                return (
                                    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
                                        <Text fw={500}>
                                            {label}
                                        </Text>
                                        {payload.length > 0 && (
                                            <>
                                                <Text fw={500} mb={5} fz="sm">
                                                    {payload[0].payload.centile > 0 && (Math.round(payload[0].payload.centile*10)/10) + "% des mesures sont en dessous"}
                                                </Text>


                                                {payload.map((item: any) => (
                                                    <Text key={item.name} c={item.color} fz="sm">
                                                        {item.value === 0 ? "Aucune mesure" : "Nombre de mesures: " + item.value}
                                                        {/*{item.value === 0 && "Aucune mesure"}*/}
                                                    </Text>
                                                ))}
                                            </>
                                        )}
                                    </Paper>
                                );
                            },
                        }}
                        valueFormatter={value => value < 0 ? (value * 10000) + "" : value + ""}
                        series={[
                            // { name: 'max', color: 'red.6' },
                            // { name: 'temp', color: 'blue.6' },
                            {name: 'compt', color: 'blue.6', label: "Nombre de mesures"},
                            // { name: 'centile', color: 'dsfsdf', label:"Centile" },
                        ]}
                    />
                </>
            )
        }
    }, [mesures_data]);

    async function ajaxMesures(cellule_id:number)
    {
        let _mesures:any
        await ajax.get('/cellules/' + cellule_id + '/mesures' + "?debut=" + date_debut.toISOString() + "&fin=" + date_fin.toISOString() + "&light=true")
            .then(response => {
                _mesures = response.data

            }).catch(err => {
            set_btn_loading(false);
        })
        return _mesures;
    }


    function downloadCSV() {
        set_btn_loading(true);
        ajaxMesures(cellule.id).then(data => {
            let csvString = ""
                + "Export des mesures de la cellule " + cellule.nom + "\n"
                + "Fuseau horaire : " + new DateTZ().getApplyiedTimezoneOffset() + "min\n"
                + "\nDate;Heure;" + cellule.sonde_type?.affichage_nom_mesure + " (" + cellule.sonde_type?.affichage_suffix + ")\n";

            let mesures: Mesure[] = JSON.parse(JSON.stringify(data.mesures));
            mesures.map(m => {
                let heure = new DateTZ(m.enregistrement)
                csvString += '"' + afficherDate(heure) + '";"'
                    + heure.getHours().toString().padStart(2, '0') + ":" + heure.getMinutes().toString().padStart(2, '0') + ":" + heure.getSeconds().toString().padStart(2, '0')
                    + '";' + m.mesure.replace(".", ",") + '\n'
            })

            const bom = '\uFEFF'; // UTF-8 BOM
            const blob = new Blob([bom + csvString], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = "export_" + cellule.nom + "_"+afficherDate(date_debut).replace("/", "-") + "_"+afficherDate(date_fin).replace("/", "-");

            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            set_btn_loading(false);
        })

    }

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeChartLine/>Graphiques & exports</button>
            <Modal opened={opened} onClose={handlers.close} title={"Graphiques et exports de données pour la cellule " + cellule.nom} className={"modale_graphique"} style={{flex: 1}}>

                <div className={"en-ligne justify_center"} style={{gap: "1rem"}}>
                    <div className={"en-ligne flex_align_center"}>
                        <p>Début : </p>
                        <DateTimePicker
                            value={date_debut}
                            locale={"Fr"}
                            onChange={e => e ? setDate_debut(new DateTZ(e.getTime())) : ""}
                        />
                    </div>
                    <div className={"en-ligne flex_align_center"}>
                        <p>Fin : </p>
                        <DateTimePicker
                            value={date_fin}
                            locale={"Fr"}
                            onChange={e => e ? setDate_fin(new DateTZ(e.getTime())) : ""}
                        />
                    </div>
                    <div className={"en-ligne flex_align_center"}>
                        <Loading_button is_loading={btn_loading} onClick={() => load_mesures(cellule.id)}><IconeChartLine/> Afficher les graphiques</Loading_button>
                        <Loading_button is_loading={btn_loading} onClick={() => load_mesures(360, true)}><IconeChartLine/> Ajouter autre </Loading_button>
                        <Loading_button is_loading={btn_loading} onClick={downloadCSV}><IconeFileExcel/> Exporter en CSV</Loading_button>
                    </div>

                </div>

                <div className={"mesures mesures_7 mesures_hide_conditions mesures_mode_compact"}>
                    <div className={"mesures_historique vue-resume"}>
                        {mesures}
                    </div>
                </div>




            </Modal>
        </>

    )
}