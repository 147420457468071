import {Navbar} from "../../components/navbar/navbar";
import {Bandeau_user} from "../../components/sidebar/bandeau_user/bandeau_user";
import React, {forwardRef, useEffect, useState} from "react";
import './Capteur_admin.scss'
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Loading_div} from "../../components/loading_div/loading_div";
import {IconeLoader} from "../../components/icone/icone";
import {Capteur} from "../../types/capteur";
import ajax from "../../services/AxiosInterceptor";
import {afficherDateTime, DateTZ} from "../../services/GestionDates";
import {Loading_button} from "../../components/loading_button/loading_button";
import {notifications} from "@mantine/notifications";
import {Modale_changer_sonde} from "./modale_changer_sonde";
import {Modale_changer_configurations} from "./modale_changer_configurations";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {Organisation} from "../../types/organisation";
import env_config from "../../env_config";
import {Icone_signal} from "../../components/icone/icone_signal/Icone_signal";
import {Avatar, Button, Group, Menu, MultiSelect, rem, Tabs, TextInput, Tooltip} from "@mantine/core";
import {Icone_batterie} from "../../components/icone/icone_batterie/Icone_batterie";
import Sidebar from "../../components/sidebar/Sidebar";
import {setMobile_sidebar_icon, setVueEnDirectSettings_orgas} from "../../store/interfaceSlice";
import {Modale_ordre_remonter_mesure} from "./modale_ordre_remonter_mesure";
import {Extension} from "../../types/extension";
import {Zone} from "../../types/zone";
import {Modale_configurer_cellule} from "../Mesures/Modales/modale_configurer_cellule";
import {Modale_etalonner} from "./modale_etalonner";
import {Modale_creer_zone} from "../../components/Modales/modale_creer_zone";
import {editOrganisation} from "../../store/organisationSlice";
import {permissionIsGranted} from "../../services/permissionsService";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {IconeCaretDown} from "../../components/icone/solid_react/caret-down";
import {IconeSquarePlus} from "../../components/icone/solid_react/square-plus";
import {IconePlus} from "../../components/icone/solid_react/plus";
import {IconeGear} from "../../components/icone/solid_react/gear";
import {IconeTrash} from "../../components/icone/solid_react/trash";
import {IconeSquarePen} from "../../components/icone/solid_react/square-pen";
import {IconeCircleInfo} from "../../components/icone/solid_react/circle-info";
import {IconeArrowUpRight} from "../../components/icone/solid_react/arrow-up-right";
import {IconeSatelliteDish} from "../../components/icone/solid_react/satellite-dish";
import {IconeEnvelope} from "../../components/icone/solid_react/envelope";
import {IconeWifi} from "../../components/icone/solid_react/wifi";
import {IconeRectangleVerticalHistory} from "../../components/icone/solid_react/rectangle-vertical-history";
import {IconePlug} from "../../components/icone/solid_react/plug";
import {Carte_mesure} from "../../components/carte_mesure/carte_mesure";

export function Capteur_admin() {

    let capteur_id = useParams().capteurId;
    const [capteur, setCapteur] = useState<Capteur | null>(null);
    const [btn_save_loading, setBtn_save_loading] = useState(false);
    const organisations_user_est_admin = useSelector<RootState, Organisation[]>(state => state.organisation.list.filter(o => o.permissions?.includes("Administrer_organisation") && o.id !== capteur?.organisation_id))
    let organisation_celle_ci = useSelector<RootState, Organisation[]>(state => state.organisation.list.filter(o => o.id === capteur?.organisation_id))

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [btn_bouger_orga_loading, setBtn_bouger_orga_loading] = useState(false);

    const navigate = useNavigate();
    const user = useSelector<RootState, User>(state => state.user)

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("pen-to-square"))

    const [organisationModaleCreerZone, setOrganisationModaleCreerZone] = useState<Organisation | null>(null);

    let organisation: Organisation | null = null
    if (organisation_celle_ci.length > 0) organisation = organisation_celle_ci[0]

    let user_permission_administrer = permissionIsGranted(organisation?.id, "Administrer_appareils")


    const [extensions_a_afficher, setExtensions_a_afficher] = useState<string[]>([]);

    const [multiselect_open, setMultiselect_open] = useState(false);

    const [btn_add_cellule_loading, setBtn_add_cellule_loading] = useState(false);


    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        image: string;
        label: string;
        description: string;
    }

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({image, label, description, ...others}: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group wrap={"nowrap"}>
                    <Avatar src={image}/>

                    <div>
                        <p>{label}</p>
                        <p className={"description-custom-multi-select"}>{description}</p>
                    </div>
                </Group>
            </div>
        )
    );

    useEffect(() => {
            ajax.get('/capteurs/' + capteur_id)
                .then(response => {
                    let received_capteur: Capteur | null = response.data.capteur;
                    setCapteur(received_capteur);
                    setExtensions_a_afficher(received_capteur?.extensions ? received_capteur.extensions.filter(e => e.cellule).map(e => e.id + "") : []);
                }).catch(error => {
            });
        },
        [capteur_id]
    );

    // useEffect(() => {
    //     if (capteur?.extensions)
    //     {
    //         let tmp_tab = [...extensions_a_afficher, ...capteur.extensions.filter(e => e.cellule).map(e => e.id + "")];
    //         tmp_tab = tmp_tab.filter((value, index) => tmp_tab.indexOf(value) === index);
    //         setExtensions_a_afficher(tmp_tab)
    //     }
    // }, [extensions_a_afficher]);


    if (!capteur) {
        return (
            <div className={"app"}>
                <Navbar/>
                <div className={"content"}>
                    <p>&nbsp;</p>
                    <Loading_div customTexte={"Chargement de l'enregistreur " + capteur_id + "..."}/>
                </div>

                <Sidebar>
                    <Bandeau_user/>
                    <Loading_div/>
                </Sidebar>
            </div>
        )
    }

    if (!capteur.extensions) {
        return (
            <div className={"app"}>
                <Navbar/>
                <div className={"content"}>
                    Enregistreur {capteur_id}

                    Erreur : Pas d'extensions trouvée...
                </div>

                <Sidebar>
                    <Bandeau_user/>
                </Sidebar>
            </div>
        )
    }

    function handleSauvegarder() {
        if (!btn_save_loading) setBtn_save_loading(true);
        console.log(capteur)

        ajax.put("/capteurs/" + capteur?.uuid, capteur).then(response => {
            setBtn_save_loading(false);
            notifications.show({
                id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Enregistré!",
                message: 'Vos données sont sauvegardés.',
                icon: <IconeCheck/>,
                className: 'my-notification-class',
                loading: false,
            });
        }).catch(error => {
        });
    }

    function handleSupprimerCapteur() {
        if (!window.confirm("ATTENTION : Vous êtes sur le point de supprimer cet enregistreur de votre organisation. " +
            "Cela va le faire disparaitre de votre organisation et désactiver toutes les cellules liés à l'enregistreur. " +
            "Etes vous sûr de vouloir continuer ?")) {
            return;
        }
        if (!btn_save_loading) setBtn_save_loading(true);

        ajax.put("/capteurs/" + capteur?.uuid, {
            organisation_id: null
        }).then(response => {
            notifications.show({
                id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'orange',
                title: "Enregistreur supprimé",
                message: 'L\enregistreur à bien été retiré de votre organisation.',
                icon: <IconeCheck/>,
                className: 'my-notification-class',
                loading: false,
            });

            if (organisation?.id) navigate("/organisation/" + organisation?.id)
            else navigate("/")
        }).catch(error => {
            setBtn_save_loading(false);
        });

    }

    function creerCelluleAvecCapteur(capteur: Capteur, zone: Zone) {
        if (!btn_save_loading) setBtn_save_loading(true);

        // alert(extension.nom + " dans " + zone.nom);
        ajax.post("/zones/" + zone.id + "/cellules", {
            nom: "Nouvelle cellule"
        }).then(response => {
            setBtn_save_loading(false);
            navigate("/cellule/" + response.data.cellule.id + "?forcerconfig=true&capteur=" + capteur.uuid)
        }).catch(error => {
        });

    }

    function creerCelluleAvecExtension(extension: Extension, zone: Zone) {
        if (!btn_save_loading) setBtn_save_loading(true);

        // alert(extension.nom + " dans " + zone.nom);
        ajax.post("/zones/" + zone.id + "/cellules", {
            nom: capteur?.nom + " - " + extension.nom
        }).then(response => {
            setBtn_save_loading(false);
            navigate("/cellule/" + response.data.cellule.id + "?forcerconfig=true&capteur=" + extension.capteur.uuid + "&extension=" + extension.id)
        }).catch(error => {
        });

    }

    enum ErrorCode {
        TEMPO = 1,
        NAND_READ = 2,
        NAND_WRITE = 4,
        NAND_FULL = 8,
        NAND_LORAWAN = 16,
        // Add more error codes as needed
    }

    function decodeErrors(errorByte: number): ErrorCode[] {
        const errorCodes: ErrorCode[] = [];

        for (let i = 0; i < 32; i++) {
            const bitValue = 1 << i;
            if ((errorByte & bitValue) !== 0) {
                errorCodes.push(bitValue);
            }
        }

        return errorCodes;
    }

    const multiselectdata = hideExtensionsAdminOnly(capteur.extensions).map(e => {
        // return {value: e.id + "", label:e.nom + (e.cellule ? " (Actif)" : "")}
        return {
            image: '/logo192.png',
            label: e.nom + (e.cellule ? " (Actif)" : ""),
            value: e.id + "",
            description: '' + (e.cellule ? "Cellule connecté : " + e.cellule.nom : "")//(e.sonde ? "Sonde indiquée : " + e.sonde.nom : "") +
        }
    });

    function deplacerCapteurOrga(orga: Organisation) {
        setBtn_bouger_orga_loading(true);
        if (window.confirm(
            "Vous allez déplacer cet enregistreur vers l'organisation " + orga.nom + "."
            + "\nCelà aura pour conséquence de désactiver toutes les cellules liées à cet enregistreur au sein de votre organisation actuelle, et l'enregistreur n'aura aucune configuration dans la nouvelle organisation."
            + "\nEtes-vous sûr de votre choix ? Vous ne pourrez pas revenir en arrière"
        )) {
            ajax.put("/capteurs/" + capteur?.uuid, {
                "nom": capteur?.uuid,
                "organisation_id": orga.id
            }).then(response => {
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Capteur déplacé!",
                    message: 'Vous le trouverez dans votre organisation ' + orga.nom,
                    icon: <IconeCheck/>,
                });
                window.location.reload();
                setBtn_bouger_orga_loading(false);
            }).catch(error => {
                setBtn_bouger_orga_loading(false);
            })
        } else setBtn_bouger_orga_loading(false);


    }

    function handleAddCellule(zone_id: number|undefined) {
        if (!zone_id) return;
        if (!btn_add_cellule_loading) setBtn_add_cellule_loading(true);
        ajax.post('/zones/' + zone_id + "/cellules", {
            "nom": "Nouvelle cellule"
        })
            .then(response => {
                if (organisation) {
                    setBtn_add_cellule_loading(false);
                    navigate("/cellule/" + response.data.cellule.id + "?forcerconfig=true");

                } else alert("Erreur, merci de rafraichir la page.");

            })
            .catch(error => {
                console.log(error);
            });

    }


    function hideExtensionsAdminOnly(extensions: Extension[]): Extension[] {
        if (permissionIsGranted(undefined, "ADMIN")) return extensions
        return extensions.filter(e => !e.extension_dispo.optionnel)
    }

    let zone_par_defaut:Zone|undefined = undefined;
    if (organisation?.zones?.length === 2){
        zone_par_defaut = organisation?.zones?.find(z => z.nom !== "base.config.appareils")
    }

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content page_capteur"}>


                <div className={"en-tete"}>
                    <div>
                        {capteur.extensions[0].organisation && (
                            <Link to={"/organisation/" + capteur.extensions[0].organisation.id}>
                                <h2> {capteur.extensions[0].organisation.nom}</h2></Link>
                        )}

                        <h1 style={{color: "black"}}>
                            Enregistreur {capteur.ref}
                        </h1>
                    </div>

                    {user_permission_administrer && (
                        <Loading_button onClick={handleSauvegarder} is_loading={btn_save_loading}
                                        icone={"floppy-disk"}>Enregistrer</Loading_button>
                    )}

                </div>


                {user_permission_administrer && (
                    <TextInput className={"nomCapteur"}
                               placeholder={"Nom de l'appareil"}
                               value={capteur.nom}
                               onChange={event => {
                                   const maj = {...capteur};
                                   maj.nom = event.target.value;
                                   setCapteur(maj);
                               }}
                    />
                )}

                <Tabs defaultValue="cellules">
                    <Tabs.List>
                        <Tabs.Tab value="cellules" leftSection={<IconeRectangleVerticalHistory/>}>
                            Cellules
                        </Tabs.Tab>
                        <Tabs.Tab value="extensions" leftSection={<IconePlug/>}>
                            Ports d'extensions
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="cellules">


                        {capteur.extensions.filter((e => e.cellule)).length === 0 ? (
                            <>
                                <h1>Bienvenue sur la page de votre nouvel appareil !</h1>
                                {capteur.extensions.length > 1 && (
                                    <div style={{display: "flex", flexFlow: "column nowrap", gap: "1rem"}}>
                                        <p>
                                            Votre appareil peut générer plusieurs types de données simultanément grâce à
                                            ses ports d'extension.
                                        </p>
                                        <p>
                                            Chaque port envoie ses mesures sur un canal spécifique.
                                            Pour collecter ces données correctement, nous vous demandons de configurer
                                            une cellule virtuelle pour chacun d'eux.
                                        </p>
                                    </div>
                                )}

                                <div style={{margin: '1rem 0'}}>
                                    <strong>
                                        Continuer votre configuration en créant une première cellule :
                                    </strong>
                                </div>
                            </>
                        ) : (

                            <p className={""} style={{padding: "1rem 0 1rem 0"}}>
                                Les cellules liés à cet appareil :
                            </p>
                        )}


                        <div
                            className={"mesures" + (capteur.extensions.filter((e => e.cellule)).length === 0 ? " justify_center" : "")}>
                            {capteur.extensions.map(extension => {
                                let cellule = extension.cellule;
                                if (!cellule) return <></>
                                return (
                                    <Carte_mesure titre={cellule.nom}
                                                  sonde={cellule.sonde_type}
                                                  texte={cellule.last_mesures?.mesure}
                                                  key={cellule.id}
                                                  handleClick={() => {
                                                      navigate("/cellule/" + cellule?.id)
                                                  }}
                                        // handleClick={() => {}}
                                                  conditions={cellule.statut_actuel?.statut.can_use_alertes ? cellule.conditions : []}
                                                  afficherTypeSiPasIcone={true}
                                                  afficherCondition={true}
                                                  afficherEtatExtension={true}
                                                  extension={cellule.extension}
                                    />
                                )
                            })}

                            {!capteur.organisation_id && (
                                <p className={"notice"}>
                                    Cet enregistreur n'appartient à aucune organisation.
                                </p>
                            )}


                            {organisation?.zones && organisation?.zones?.length > 1 ? (
                                <>
                                    {permissionIsGranted(Number(organisation?.id), "Administrer_cellule")
                                        && capteur.extensions.filter((e => !e.cellule)).length > 0
                                        && (
                                            <>
                                                {organisation?.zones.length === 2 && zone_par_defaut ? (
                                                    <div className={"carte_mesure bleu white"}
                                                         style={{borderBottom: "unset"}}
                                                        onClick={() => handleAddCellule(zone_par_defaut?.id)}
                                                    >
                                                        <div className={"titre"}>
                                                            <div className={"titre_container"}>
                                                                <p className={"titre_texte"}>Créer une cellule</p>
                                                            </div>
                                                        </div>
                                                        <p className={"valeur"}>{btn_add_cellule_loading ?
                                                            <IconeLoader/> :
                                                            <IconePlus/>}</p>
                                                    </div>
                                                ) : (
                                                    <Menu shadow="md" width={200} withArrow={true} arrowSize={10}
                                                          offset={-8}>
                                                        <Menu.Target>
                                                            <div className={"carte_mesure bleu white"}
                                                                 style={{borderBottom: "unset"}}
                                                                // onClick={() => handleAddCellule(zone.id)}
                                                            >
                                                                <div className={"titre"}>
                                                                    <div className={"titre_container"}>
                                                                        <p className={"titre_texte"}>Créer une
                                                                            cellule</p>
                                                                    </div>
                                                                </div>
                                                                <p className={"valeur"}>{btn_add_cellule_loading ?
                                                                    <IconeLoader/> :
                                                                    <IconePlus/>}</p>
                                                            </div>
                                                        </Menu.Target>

                                                        <Menu.Dropdown>
                                                            <Menu.Label>Créer dans la zone :</Menu.Label>
                                                            {organisation?.zones?.map(zone => {
                                                                if (zone.nom === "base.config.appareils") return <></>
                                                                return (
                                                                    <Menu.Item
                                                                        onClick={() => creerCelluleAvecCapteur(capteur, zone)}>
                                                                        {zone.nom}
                                                                    </Menu.Item>
                                                                )
                                                            })}
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    setOrganisationModaleCreerZone(organisation)
                                                                }}
                                                                className={"en-ligne"}
                                                                leftSection={<IconePlus/>}
                                                            >
                                                                Créer une zone
                                                            </Menu.Item>
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                )}

                                            </>
                                        )}
                                </>
                            ) : (
                                <p className={"notice grey en-ligne"}>
                                    Avant de pouvoir créer une cellule, vous devez créer une zone dans laquelle la
                                    mettre :
                                    <button onClick={() => {
                                        setOrganisationModaleCreerZone(organisation)
                                    }}>Créer une zone</button>
                                </p>
                            )}


                        </div>


                    </Tabs.Panel>

                    <Tabs.Panel value="extensions">


                        <p className={"inline-tag texte-normal"} style={{margin: "1rem 0 0.7rem 0"}}>
                            <IconeCircleInfo/> Chaque enregistreur à plusieurs ports d'extensions.
                            Voici un résumé des extensions et de leur utilisations.
                        </p>

                        <MultiSelect
                            description={"Indiquez les extensions que vous souhaitez afficher : "}
                            placeholder={"Selectionner les extensions à afficher"}
                            data={multiselectdata}
                            value={extensions_a_afficher}
                            dropdownOpened={multiselect_open}
                            onClick={() => setMultiselect_open(!multiselect_open)}
                            onChange={(e) => {
                                setExtensions_a_afficher(e)
                                setMultiselect_open(false)
                            }}
                            renderOption={({option}) => (
                                <Group gap="sm">
                                    {/*<Avatar src={usersData[option.value].image} size={36} radius="xl" />*/}
                                    <div>
                                        <p className={"en-ligne"}>
                                            {extensions_a_afficher.includes(option.value) ? (<IconeCheck/>) : ""}
                                            {multiselectdata.find(e => e.value === option.value)?.label ?? ""}
                                        </p>
                                        <p className={"texte-info"}>
                                            {multiselectdata.find(e => e.value === option.value)?.description ?? ""}
                                        </p>
                                    </div>
                                </Group>
                            )}
                            // itemComponent={SelectItem}
                            // withinPortal={disableTemporaryMultiSelect}
                        />


                        <div className={"extensions"}>
                            {capteur.extensions.filter(e => extensions_a_afficher.includes(e.id + "")).length === 0 && (
                                <p className={"notice grey"}>Merci de selectionner les extensions à afficher avec le
                                    menu ci dessus</p>
                            )}
                            {capteur.extensions.filter(e => extensions_a_afficher.includes(e.id + "")).map(extension => {
                                return (
                                    <div
                                        className={"extension" + (queryParams.get('cellule') ? queryParams.get('cellule') === extension.cellule?.id + "" ? " selected" : "" : "")}
                                        key={extension.id}>
                                        <div className={"en-ligne spacebetween"}>
                                            <h3>{extension.extension_dispo.nom}</h3>

                                            {user_permission_administrer && ((extension.sonde && extension.client_peut_modifier_statut) || (extension.sonde && extension.cellule)) && (
                                                <Menu shadow="md" position={"bottom-end"} zIndex={1000}
                                                      closeOnItemClick={false}
                                                      closeOnClickOutside={false}>
                                                    <Menu.Target>
                                                        <button className={"sec"}><IconeCaretDown/></button>
                                                    </Menu.Target>

                                                    <Menu.Dropdown>
                                                        <Menu.Label>Options :</Menu.Label>
                                                        <div className={"flex-col"} style={{flex: "1", gap: "0.5rem"}}>
                                                            {extension.cellule && (
                                                                <Modale_etalonner extension={extension}
                                                                                  capteur={capteur}
                                                                                  setCapteur={setCapteur}/>
                                                            )}
                                                            {organisation?.permissions?.includes("Administrer_appareils")
                                                                && extension.client_peut_modifier_statut && (
                                                                    <Modale_changer_sonde extension={extension}
                                                                                          capteur={capteur}
                                                                                          setCapteur={setCapteur}
                                                                                          dropdown_btns={true}/>
                                                                )}

                                                        </div>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            )}

                                        </div>

                                        {user_permission_administrer && (
                                            <TextInput
                                                placeholder={"Nom personnalisé de l'extension"}
                                                value={extension.nom}
                                                onChange={event => {
                                                    const maj = {...capteur};
                                                    if (maj.extensions) maj.extensions = maj.extensions.map(_extension => {
                                                        if (_extension.id === extension.id) {
                                                            _extension.nom = event.target.value;
                                                        }
                                                        return _extension;
                                                    })
                                                    setCapteur(maj);

                                                }
                                                }
                                            />
                                        )}

                                        <div className={"infos"}>
                                            <div>
                                                <p>Sonde
                                                    connectée: <i>{extension.sonde == null ? "Aucune" : extension.sonde.nom}</i>
                                                </p>
                                                {extension.cellule == null ? (
                                                    <p className="en-ligne">Cellule connectée: Aucune</p>
                                                ) : (
                                                    <p className="en-ligne">Cellule connectée:<Link
                                                        to={"/cellule/" + extension.cellule.id}
                                                        className="en-ligne inline-tag">
                                                        <i className={"inline-tag " + (extension.cellule?.last_mesures ? "green" : "grey")}>
                                                            {
                                                                extension.cellule?.last_mesures && (
                                                                    <>
                                                                        {extension.sonde?.type_de_donnee === "bool" ? (
                                                                                (extension.cellule?.last_mesures?.mesure === "1" ? extension.sonde?.affichage_vrai : extension.sonde?.affichage_faux)
                                                                            ) :
                                                                            "" + extension.cellule?.last_mesures?.mesure + extension.sonde?.affichage_suffix
                                                                        }
                                                                    </>
                                                                )
                                                            }


                                                        </i>
                                                        {extension.cellule.nom}
                                                    </Link>
                                                    </p>
                                                )}

                                            </div>

                                        </div>

                                        <div className={"en-ligne"} style={{justifyContent: "space-evenly", flex: "1"}}>
                                            {permissionIsGranted(organisation?.id, "Administrer_cellule") && extension.sonde && !extension.cellule && (
                                                <>
                                                    <Menu shadow="md" width={200}>
                                                        <Menu.Target>
                                                            <button className={""}>
                                                                {btn_save_loading ? (
                                                                    <>
                                                                        <IconeLoader/> Chargement...
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconeSquarePlus/> Créer la cellule
                                                                    </>
                                                                )}
                                                            </button>
                                                        </Menu.Target>

                                                        <Menu.Dropdown>
                                                            <Menu.Label>Dans la zone :</Menu.Label>
                                                            {organisation?.zones?.map(zone => {
                                                                if (zone.nom === "base.config.appareils") return <></>
                                                                return (
                                                                    <Menu.Item
                                                                        onClick={() => creerCelluleAvecExtension(extension, zone)}>
                                                                        {zone.nom}
                                                                    </Menu.Item>
                                                                )
                                                            })}
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    setOrganisationModaleCreerZone(organisation)
                                                                }}
                                                                className={"en-ligne"}
                                                                leftSection={<IconePlus/>}
                                                            >
                                                                Créer une zone
                                                            </Menu.Item>
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                </>

                                            )}
                                            {permissionIsGranted(organisation?.id, "Administrer_cellule") && extension.sonde && extension.cellule && (
                                                <>
                                                    <Modale_configurer_cellule cellule={extension.cellule}
                                                                               setCellule={newcellule => {
                                                                                   if (newcellule) {
                                                                                       let _capteur = {...capteur}
                                                                                       _capteur.extensions = _capteur?.extensions?.map(ext => {
                                                                                           let _ext = {...ext}
                                                                                           if (_ext.id === extension.id) {
                                                                                               _ext.cellule = newcellule
                                                                                               if (newcellule.extension === null) _ext.cellule = undefined
                                                                                           }
                                                                                           return _ext
                                                                                       })
                                                                                       setCapteur(_capteur)
                                                                                   }
                                                                               }} forcer={false}>
                                                        <>
                                                            <IconeSquarePen/> Configurer la cellule
                                                        </>
                                                    </Modale_configurer_cellule>
                                                </>
                                            )}
                                        </div>

                                        {organisation?.permissions?.includes("Administrer_appareils")
                                            && extension.client_peut_modifier_statut && (
                                                <Modale_changer_sonde extension={extension} capteur={capteur}
                                                                      setCapteur={setCapteur} dropdown_btns={false}/>
                                            )}

                                    </div>
                                )
                            })}
                        </div>
                    </Tabs.Panel>

                </Tabs>


            </div>

            <Modale_creer_zone organisation={organisationModaleCreerZone} setOrganisation={orga => {
                setOrganisationModaleCreerZone(null);
                if (orga) {
                    dispatch(editOrganisation(orga))
                }

            }}></Modale_creer_zone>


            <Sidebar>
                <Bandeau_user/>
                <div className={"actions"}>
                    <h3><IconeCircleInfo/> Informations de fonctionnement</h3>
                    <p className={"infos"}>Dernière connexion
                        : <i
                            className="inline-tag">{capteur.derniere_mesure ? afficherDateTime(new DateTZ(Date.parse(capteur.derniere_mesure))) : "Aucune"}</i>
                    </p>
                    <p className={"infos"}>Niveau de batterie :

                        <div className="tag-avec-icone en-ligne no-wrap">
                            <Icone_batterie niveau_batterie={capteur.niveau_batterie}/>
                            <i className="inline-tag">{capteur.niveau_batterie ? capteur.niveau_batterie + "%" : "Inconnu"}</i>
                        </div>
                    </p>
                    <p className={"infos"}>Code de statut :

                        <div className="tag-avec-icone en-ligne"
                             onClick={() => alert("Le code de statut est un indicateur remonté par l'enregistreur pour indiquer quand un problème matériel est détecté.")}>
                            {capteur.error_code === 0 ? (
                                <i className="inline-tag green">RAS</i>
                            ) : (
                                <i className="inline-tag red">{capteur.error_code < 129
                                    ? <>
                                        Erreurs enregistreurs :
                                        {decodeErrors(capteur.error_code).map(errorCode =>
                                            <i> - {ErrorCode[errorCode]}</i>)}
                                    </>
                                    : (
                                        <>
                                            {capteur.error_code === 300 && "L'heure indiquée par l'enregistreur est d'au moins 15 minutes dans le futur, les mesures sont donc refusés."}
                                            {capteur.error_code === 500 && "Erreur plateforme"}
                                        </>
                                    )
                                }</i>
                            )}

                        </div>
                    </p>
                    <p className={"infos"}>Niveau du signal :

                        <div className={"en-ligne no-wrap"}>
                            <div className="tag-avec-icone" style={{cursor: 'pointer'}}
                                 onClick={() => alert("Le RSSI indique la puissance reçue du signal par la gateway. Généralement, la distance entre l'enregistreur et la gateway influe sur cette valeur.")}>
                                <Icone_signal rssi={capteur.rssi ? capteur.rssi : undefined}/>
                                <i className="inline-tag">RSSI {capteur.rssi ? capteur.rssi + "dBm" : "Inconnu"} </i>
                            </div>


                            <div className="tag-avec-icone" style={{cursor: 'pointer'}}
                                 onClick={() => alert("Le SNR indique la puissance du signal par rapport aux interférences présentes dans votre établissement. Si le SNR est positif, le signal est plus fort que tout le reste. Si par contre le SNR est négatif, le signal pourrait ne plus être démodulé par la gateway. Si le niveau du SNR est trop mauvais, essayez d'éloigner l'enregistreur de sources de bruits électromagnétiques.")}>
                                <Icone_signal snr={capteur.snr ? capteur.snr : undefined}/>
                                <i className="inline-tag">SNR {capteur.snr ? capteur.snr + "dB" : "Inconnu"}</i>
                            </div>
                        </div>


                    </p>

                    <p className={"infos"}>Dernier hub utilisé :
                        {capteur.hub ? (
                            <>
                                {capteur.hub.uuid === "WIFI" ? (
                                    <p className={"inline-tag"}>
                                        <IconeWifi/> {capteur.hub.nom !== "Inconnu" ? capteur.hub.nom : capteur.hub.uuid}
                                    </p>
                                ) : (
                                    <button className={"sec"}
                                            onClick={() => navigate("/hubs/?uuid=" + capteur.hub.uuid)}>
                                        <IconeSatelliteDish/> {capteur.hub.nom !== "Inconnu" ? capteur.hub.nom : capteur.hub.uuid}
                                    </button>
                                )}
                            </>
                        ) : " Aucun"}

                    </p>

                    {permissionIsGranted(organisation?.id, "ADMIN") ? (
                        <p className={"infos"}>Numéro de série : {capteur.numero_serie ?? "Aucun"} </p>
                    ) : <></>}


                    <h3><IconeGear/> Actions disponibles pour cet enregistreur</h3>


                    {organisation?.permissions?.includes("Administrer_appareils") ? (
                        <>

                            <div className={"carte_action"}>
                                <p className={"titre"}>Alertes de l'enregistreur</p>
                                <p className={"infos"}>Gérer l'anomalie de déconnexion automatique et de batterie faible
                                    et connectez y des
                                    alertes.
                                    Si vous voulez créer une anomalie de déconnexion plus avancé, vous pouvez le faire
                                    depuis n'importe quelle cellule, avec l'option anomalies avancées.</p>
                                <Link className={"btn sec"}
                                      to={"/organisation/" + organisation?.id + "/anomalies?cellule=" + capteur.uuid}><IconeEnvelope/> Anomalies</Link>
                            </div>


                            {(permissionIsGranted(-1, "ADMIN") || (capteur.configurations && capteur.configurations.filter(config => !config.parametre.admin_only).length > 0)) && (
                                <div
                                    className={"carte_action" + ((permissionIsGranted(-1, "ADMIN") ? " prestataire" : ""))}>
                                    <p className={"titre"}>Changer la configuration</p>
                                    <p className={"infos"}>Changer la configuration de l'enregistreur, comme son délai
                                        d'actualisation ou le type de sa batterie.</p>
                                    <Modale_changer_configurations capteur={capteur} setCapteur={setCapteur}/>
                                </div>
                            )}


                            {(user.roles.includes("ROLE_PRESTATAIRE")) && (
                                <div className={"carte_action prestataire"}>
                                    <p className={"titre"}>Ordonner une remontée de mesure</p>
                                    <p className={"infos"}>Envoyer l'ordre de remonter les mesures stockés en mémoire
                                        pendant une période</p>
                                    <Modale_ordre_remonter_mesure capteur={capteur} setCapteur={setCapteur}/>
                                </div>
                            )}

                            {organisations_user_est_admin.length > 1 && (
                                <div className={"carte_action"}>
                                    <p className={"titre"}>Déplacer l'enregistreur</p>
                                    <p className={"infos"}>Déplacer l'enregistreur vers une autre organisation. Si des
                                        cellules
                                        sont liés à cet enregistreur, elles seront désactivée. </p>
                                    {btn_bouger_orga_loading ? (
                                        <Loading_button is_loading={true} onClick={() => {
                                        }}>Chargement...</Loading_button>
                                    ) : (
                                        <Menu shadow="md" width={200}>
                                            <Menu.Target>
                                                <button className={"sec"}><IconeArrowUpRight/>Déplacer vers
                                                    ... <IconeCaretDown/></button>
                                            </Menu.Target>

                                            <Menu.Dropdown>
                                                <Menu.Label>Vers l'organisation...</Menu.Label>
                                                {organisations_user_est_admin.map(orga => {
                                                    return (
                                                        <Menu.Item onClick={() => deplacerCapteurOrga(orga)}>
                                                            {orga.nom}
                                                        </Menu.Item>
                                                    )
                                                })}
                                            </Menu.Dropdown>
                                        </Menu>
                                    )}

                                </div>
                            )}

                            <div className={"carte_action"}>
                                <p className={"titre"}>Supprimer l'enregistreur</p>
                                <p className={"infos"}>Suppimer l'enregistreur va le faire disparaître completement de
                                    votre
                                    organisation. Si des cellules sont liés à cet enregistreur, elles seront
                                    désactivée.</p>
                                <Loading_button is_loading={btn_save_loading} onClick={handleSupprimerCapteur}
                                                className={"sec"}><IconeTrash/>Supprimer</Loading_button>
                            </div>
                        </>
                    ) : (
                        <div className={"infos"}>Aucune action disponible.</div>
                    )}

                </div>
            </Sidebar>
        </div>

    );
}
