import {Navbar} from "../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Notifications_tableau.scss'
import {Tableau} from "../../components/tableau/Tableau";
import {Notifications} from "../../types/notifications";
import ajax from "../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {afficherDateTime, DateTZ} from "../../services/GestionDates";
import {NativeSelect, Tooltip} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {info} from "sass";
import {Organisation} from "../../types/organisation";
import {Bandeau_user} from "../../components/sidebar/bandeau_user/bandeau_user";
import {Cellule} from "../../types/cellule";
import {Hub} from "../../types/hub";
import {useDispatch, useSelector} from "react-redux";
import {setMobile_sidebar_icon} from "../../store/interfaceSlice";
import {
    texte_custom_compare_tableau,
    texte_est_inclus,
    texte_simple_compare_tableau
} from "../../services/GestionTextes";
import {getAffichageNom} from "../../services/UserService";
import {IconeEye} from "../../components/icone/solid_react/eye";
import {DateInput, DatePicker, DateTimePicker} from "@mantine/dates";


export function Notifications_tableau() {

    const [donnees, setDonnees] = useState<Notifications[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])

    const navigate = useNavigate();

    const columnHelper = createColumnHelper<Notifications>()

    let moisavant = new DateTZ();
    moisavant.setMonth(moisavant.getMonth() - 1);
    const [date_debut, setDate_debut] = useState<DateTZ>(moisavant);
    const [date_fin, setDate_fin] = useState<DateTZ>(new DateTZ());

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    function testSiNotifEstProcheDeConditionTemporellement(notif:Notifications)
    {
        if (notif.condition){
            let heure_notif = new DateTZ(notif.envoi_heure);
            let heure_condition = new DateTZ(notif.condition.validation);

            // alert(notif.message + " " + heure_notif.getTime() + " " +  heure_condition.getTime())

            return heure_notif.getTime() === heure_condition.getTime()
        }


        return false
    }

    const columns = [
        columnHelper.accessor(row => row.organisation, {
            id: 'Organisation',
            header: () => 'Organisation',
            filterFn: (    row: Row<Notifications>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue(columnId))
                {
                    let orga:Organisation = row.getValue(columnId);
                    return texte_est_inclus(orga.nom, filterValue) || orga.id == filterValue
                }
                return false;
            },
            cell: info =>
                info.getValue()?.nom != null && (
                <span>
                    <strong className={"th_mobile"}>Organisation : </strong>
                    <Link to={"/organisation/" + info.getValue()?.id}>{info.getValue()?.nom}</Link>
                </span>
                ),
            enableColumnFilter: true
        }),
        columnHelper.accessor(row => row, {
            id: 'Cellule',
            header: () => 'Cellule',
            cell: info =>
                info.getValue()?.est_accessible && (
                <span>
                    <strong className={"th_mobile"}>Cellule : </strong>
                    <Link to={"/cellule/" + info.getValue()?.cellule?.id}>{info.getValue()?.cellule?.nom}</Link>

                </span>
                ),
            filterFn: (    row: Row<Notifications>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue(columnId))
                {
                    let notif:Notifications = row.getValue(columnId);
                    return texte_est_inclus(notif.cellule?.nom, filterValue) || notif.cellule?.id == filterValue
                }
                return false;
            },
        }),
        columnHelper.accessor(row => row, {
            id: 'Appareil',
            header: () => 'Appareil',
            cell: info =>
                (
                <>
                    {info.getValue().type.includes("hub") ? (
                        <span>
                            <strong className={"th_mobile"}>Hub : </strong>
                            <Tooltip label={"Hub"}>
                                <Link to={"/hubs/?uuid=" + info.getValue().capteur}>{info.getValue().capteur}</Link>
                            </Tooltip>

                        </span>
                    ) : (
                        <span>
                            <strong className={"th_mobile"}>Enregistreur : </strong>
                            <Tooltip label={"Enregistreur"}>
                                <Link to={"/capteur/" + info.getValue().capteur}>{info.getValue().capteur}</Link>
                            </Tooltip>

                        </span>
                    )}
                </>
                ),
            filterFn: (    row: Row<Notifications>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue(columnId))
                {
                    let notif:Notifications = row.getValue(columnId);
                    if (notif.capteur === null) return false
                    return texte_est_inclus(notif.capteur, filterValue)
                }
                return false;
            },
        }),
        columnHelper.accessor('type', {
            id: 'Type',
            header: () => 'Type',
            cell: info => <>
                        <strong className={"th_mobile"}>Type : </strong>
                        <i className={"tag"}>
                            {info.getValue()}
                        </i>
                    </>,
            enableColumnFilter: true,
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.utilisateur ? getAffichageNom(row.utilisateur) : "", {
            id: 'Utilisateur',
            header: () => 'Utilisateur',
            cell: info =>
                info.getValue() !== "" && (
                <span>
                    <strong className={"th_mobile"}>Utilisateur : </strong>
                        <Tooltip label={info.row.original.utilisateur?.email} position={"bottom"}>
                            <p>{info.getValue()}</p>
                        </Tooltip>

                </span>
                ),
            enableColumnFilter: true,
            filterFn: (row, columnId, filterValue) =>
                texte_custom_compare_tableau(row.original.utilisateur?.nom_affichage + " " + row.original.utilisateur?.nom_famille + " " + row.original.utilisateur?.email, filterValue),
        }),
        columnHelper.accessor('message', {
            id: 'Message',
            header: () => 'Message',
            cell: info =>
                <span className={"aligne_left"}>
                    <strong className={"th_mobile"}>Message : </strong>
                    {info.getValue()}
                </span>,
            enableColumnFilter: true,
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row, {
            id: "Statut",
            header: () => 'Statut',
            cell: info =>
                (
                    <span className={"no-wrap flex_align_center"} style={{padding: "0"}}>
                        <strong className={"th_mobile"}>Actuellement : </strong>
                            {info.getValue().condition ? (
                                <Tooltip label={"Condition : " + info.getValue()?.condition?.nom}>
                                    {info.getValue()?.condition?.statut_validation > 0
                                    && testSiNotifEstProcheDeConditionTemporellement(info.getValue())
                                        ? (
                                            <div>
                                                {info.getValue().est_accessible ? (
                                                    <button  className={info.getValue()?.condition?.statut_validation > 0 ? " red" : " green"}
                                                             onClick={() => navigate("/cellule/" + info.getValue().cellule?.id)}>
                                                        {info.getValue()?.condition?.statut_validation === 0 && "Résolu"}
                                                        {info.getValue()?.condition?.statut_validation === 1 && "Anomalie"}
                                                        {info.getValue()?.condition?.statut_validation === 2 && "Hors ligne"}
                                                        {info.getValue()?.condition?.statut_validation === 3 && "Hors ligne"}
                                                        {info.getValue()?.condition?.statut_validation === 4 && "Hors ligne"}
                                                        {info.getValue()?.condition?.statut_validation === 5 && "Hors ligne"}
                                                    </button>
                                                ) : (
                                                    <div className={"inline-tag" + (info.getValue()?.condition?.statut_validation > 0 ? " red" : " green")}>
                                                        {info.getValue()?.condition?.statut_validation === 0 && "Résolu"}
                                                        {info.getValue()?.condition?.statut_validation === 1 && "Anomalie"}
                                                        {info.getValue()?.condition?.statut_validation === 2 && "Hors ligne"}
                                                        {info.getValue()?.condition?.statut_validation === 3 && "Hors ligne"}
                                                        {info.getValue()?.condition?.statut_validation === 4 && "Hors ligne"}
                                                        {info.getValue()?.condition?.statut_validation === 5 && "Hors ligne"}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                        :(
                                            <div className={"inline-tag green"}>Résolu</div>
                                        )}

                                </Tooltip>

                            ) : (
                                <div className={"inline-tag"}>Anomalie supprimée</div>
                            )}
                    </span>
                ),

            enableColumnFilter: true,
            filterFn: (    row: Row<Notifications>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue(columnId))
                {
                    let notif:Notifications = row.getValue(columnId);
                    if (!notif.condition) return false;
                    let texte = "";
                    if (notif.condition?.statut_validation > 0
                        && testSiNotifEstProcheDeConditionTemporellement(notif))
                    {
                        texte = notif.condition?.statut_validation === 0 ? "Résolu" : texte;
                        texte = notif.condition?.statut_validation === 1 ? "-err Anomalie" : texte;
                        texte = notif.condition?.statut_validation === 2 ? "-err Hors ligne" : texte;
                        texte = notif.condition?.statut_validation === 3 ? "-err Hors ligne" : texte;
                        texte = notif.condition?.statut_validation === 4 ? "-err Hors ligne" : texte;
                        texte = notif.condition?.statut_validation === 5 ? "-err Hors ligne" : texte;
                    }
                    else texte = "Résolu";
                    return texte_est_inclus(notif.condition?.nom, filterValue) || texte_est_inclus(texte, filterValue)
                }
                return false;
            },
            sortingFn: (rowA: Row<Notifications>,
                        rowB: Row<Notifications>,
                        columnId: string) => {
                let notifA = rowA.getValue<Notifications>(columnId)
                let notifB = rowB.getValue<Notifications>(columnId)
                if (notifA.condition && !notifB.condition) return 1
                if (!notifA.condition && notifB.condition) return -1
                if (notifA.condition
                    && notifA.condition?.statut_validation > 0
                    && testSiNotifEstProcheDeConditionTemporellement(notifA))
                {
                    if (notifB.condition
                        && notifB.condition?.statut_validation > 0
                        && testSiNotifEstProcheDeConditionTemporellement(notifB))
                    {
                        return 0
                    }
                    return 1
                }
                if (notifB.condition
                    && notifB.condition?.statut_validation > 0
                    && testSiNotifEstProcheDeConditionTemporellement(notifB))
                {
                    return -1
                }
                return 0;
            },
        }),
        columnHelper.accessor(row => row, {
            id: "Date",
            header: () => 'Date',
            cell: info =>
                <span className={"en-ligne no-wrap"} style={{padding: "0"}}>
                    <strong className={"th_mobile"}>Date : </strong>
                    {afficherDateTime(new DateTZ(info.getValue().envoi_heure))}
                    {info.getValue().est_accessible ? (
                        <button
                                onClick={() => navigate("/cellule/" + info.getValue().cellule?.id + "?dt=" + info.getValue().envoi_heure)}>
                            <IconeEye/>
                        </button>) : (<></>)}
                </span>,
            enableColumnFilter: true,
            filterFn: (    row: Row<Notifications>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue(columnId))
                {
                    let notif:Notifications = row.getValue(columnId);
                    return texte_est_inclus(afficherDateTime(new DateTZ(notif.envoi_heure)), filterValue)
                }
                return false;
            },
        }),
        // columnHelper.accessor(row => row, {
        //     id: 'Voir',
        //     header: () => '',
        //     cell: props => props.getValue().est_accessible ? (
        //         <button style={{marginLeft: "1rem"}}
        //         onClick={() => navigate("/cellule/" + props.getValue().cellule?.id + "?dt=" + props.getValue().envoi_heure)}>
        //         <Icone nom={"eye"}/>
        //         </button>) : (<></>),
        //     enableColumnFilter: false
        // }),
    ]

    useEffect(() => {
        setDonnees(null)
        ajax.get("/utilisateur/notifications" + "?debut=" + date_debut.toISOString() + "&fin=" + date_fin.toISOString()).then(response => {
            let notifs:Notifications[] = response.data.notifications;

            setDonnees(notifs)

        }).catch(e => {
        })
    }, [date_debut, date_fin])


    const table = useReactTable<Notifications>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    let compteur_anomalies:number[] = [];
    donnees?.map(notif => {
        if (notif.condition && notif.condition.statut_validation > 0) {
            if (!compteur_anomalies.includes(notif.condition.id)) compteur_anomalies.push(notif.condition.id);
        }
    })
    // console.log(compteur_anomalies)

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content content-fullwidth en-tete-pages"}>

                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Vos notifications</h1>
                        <p>
                            Voici un historique des notifications et alertes qui vous ont été envoyées.
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user />
                    </div>

                </div>
                <div className={"en-ligne en-tete-page"} style={{marginBottom: 0}}>

                    <div className={"en-ligne"}>
                        <div className={"en-ligne flex_align_center"}>
                            <p>Charger du : </p>
                            <DateInput
                                locale={"fr"}
                                valueFormat="DD/MM/YYYY"
                                value={date_debut}
                                onChange={e => setDate_debut(new DateTZ(e))}
                            />
                        </div>
                        <div className={"en-ligne flex_align_center"}>
                            <p>jusqu'au : </p>
                            <DateInput
                                locale={"fr"}
                                valueFormat="DD/MM/YYYY"
                                value={date_fin}
                                onChange={e => setDate_fin(new DateTZ(e))}
                            />
                        </div>
                    </div>

                    <div className={"flex-row"}>

                        <button onClick={() => {
                            // alert(JSON.stringify(table.getState().columnFilters))
                            table.setSorting([{"id":"Statut","desc":true}])
                            table.setColumnFilters([{"id":"Type","value":"-ntf"},{"id":"Statut","value":"-err"}])
                        }} className={compteur_anomalies.length > 0 ? "red" : ""}>
                            <IconeEye/> Voir les anomalies actives ({compteur_anomalies.length})
                        </button>

                        <div>{table.getRowModel().rows.length} lignes affichés</div>
                        <NativeSelect
                            className={"select_nbe_lignes match_height"}
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                            data={["5", "10", "20", "50", "100"]}
                        />
                    </div>

                </div>

                <Tableau table={table} donnees={donnees}/>
            </div>
        </div>

    );
}
