import React, {useEffect, useRef, useState} from 'react';
import '../carte_mesure.scss'
import {useNavigate} from "react-router-dom";
import {DateTZ, getCurrentDate} from "../../../services/GestionDates";
import env_config from "../../../env_config";
import {Hub} from "../../../types/hub";
import {IconeSatelliteDish} from "../../icone/solid_react/satellite-dish";

export function Carte_hub(
    {
        hub
    }: {
        hub: Hub
    }) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const titreRef = useRef<HTMLParagraphElement>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const titreElement = titreRef.current;
        if (titreElement) {
            setIsOverflowing(titreElement.scrollWidth > titreElement.clientWidth);
        }
    }, []);

    // let niveau_batterie = capteur.niveau_batterie;
    // let rssi = capteur.rssi;
    // let snr = capteur.snr;


    let couleur = "green";
    if (hub.heartbeat && new DateTZ(Date.parse(hub.heartbeat))  < new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_gateway)){
        couleur = "grey";
    }

    return (
        <div className={"carte_mesure " + couleur}
             onClick={() => navigate("/hubs/?uuid=" + hub.uuid)}>
            <div className={"titre"}>
                <IconeSatelliteDish />
                <div className={"titre_container"}>
                    <p className={"titre_texte" + (isOverflowing ? ' slide' : '')} ref={titreRef}>{hub.nom}</p>
                </div>
            </div>
            <div className={"valeur en-ligne"}>
                {/*<p style={{fontSize: "2.5rem"}}><Icone nom={"microchip"}/></p>*/}


                <p className={"inline-tag"}>{hub.modele !== "Inconnu" ? hub.modele : "Gateway"}
                    <br />
                    {hub.uuid}
                </p>
            </div>

        </div>
    );
}