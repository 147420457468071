import './Inscription.scss';
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Input, PasswordInput, Stepper, TextInput} from "@mantine/core";
import {IconeLoader} from "../../components/icone/icone";
import {Link, useParams} from "react-router-dom";
import {Loading_button} from "../../components/loading_button/loading_button";
import ajax from "../../services/AxiosInterceptor";
import {Carte_capteur} from "../../components/carte_mesure/carte_appareil/carte_capteur";
import {Carte_hub} from "../../components/carte_mesure/carte_appareil/carte_hub";
import {Carte_mesure} from "../../components/carte_mesure/carte_mesure";
import {gps_sonde} from "../../types/sonde";
import {permissionIsGranted} from "../../services/permissionsService";
import {Modale_ajouter_appareil} from "../Orga_admin/modale_ajouter_appareil";
import {Organisation} from "../../types/organisation";
import {Capteur} from "../../types/capteur";
import {Hub} from "../../types/hub";
import {DateTZ} from "../../services/GestionDates";
import {IconeRight} from "../../components/icone/solid_react/right";
import {IconeAt} from "../../components/icone/solid_react/at";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {All_permissions} from "../../types/permissions";
import env_config from "../../env_config";

export function Inscription() {

    const [stepperActive, setStepperActive] = useState(0);
    const nextStep = () => setStepperActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setStepperActive((current) => (current > 0 ? current - 1 : current));

    const [capteurs, setCapteurs] = useState<Capteur[]>([]);
    const [hubs, setHubs] = useState<Hub[]>([]);

    const [nom_organisation, set_nom_organisation] = useState("");
    const [email, set_email] = useState("");

    const [cgv, setCgv] = useState(false);
    const [cgu, setCgu] = useState(false);

    const [btn_loading, set_btn_loading] = useState(false);

    // const [organisation_tempororaire, setOrganisation_tempororaire] = useState<Organisation>(
    //     {
    //         balance: 0,
    //         capteurs: [],
    //         contacts: [],
    //         coordonnees: {},
    //         creation: "",
    //         desactivation_texte: null,
    //         etat: "",
    //         hubs: [],
    //         id: 0,
    //         is_fully_loaded: false,
    //         nom: "",
    //         orga_parent: null,
    //         permissions: [],
    //         prestataire: {id: 0, nom: ""},
    //         prochainPrelevement: null,
    //         statut: {deadline: "", nom: ""},
    //         zones: []
    //     }
    // );

    useEffect(() => {
        // ajax.get("/inscription/token/" + token).then(response => {
        //     set_token_valide(true)
        //     set_nom_affichage("")
        //     set_nom_famille("")
        //     set_email(response.data.utilisateur.email)
        //     set_telephone(response.data.utilisateur.tel)
        //
        //     set_organisations(response.data.organisations)
        // }).catch(error => {
        //     set_token_valide(false)
        // })
    }, [])

    function handleValidation() {

        set_btn_loading(true)

        ajax.post("/inscription/valider", {
            "email" : email,
            "roles_organisation": All_permissions.map(p => p.slug),
            "capteurs": capteurs,
            "hubs": hubs,
            "organisation": nom_organisation,
            "accepte_cgv": cgv,
            // "accepte_cgu": cgu,
        }).then(response => {
            // window.location.href = "/login?inscription=true";

            set_btn_loading(false)
            nextStep();
        }).catch(error => {
            set_btn_loading(false)
        })

    }

    return (
        <div className={"app inscription"}>
            <div className={"inscription_form"}>
                <img src={"/logo_freshtime.svg"} alt=""/>


                <Stepper active={stepperActive} onStepClick={stepIndex => {
                    if (stepperActive < 3) setStepperActive(stepIndex) //Pour ne pas laisser revenir en arrière après la validation.
                }} allowNextStepsSelect={false}>
                    <Stepper.Step label="Ajouter un appareil">
                        <div className={"stepper_inscription-page"}>
                            <h3>Bienvenue sur la plateforme Freshtime!</h3>
                            <p>Ici, vous retrouverez toutes les mesures que produisent vos appareils Freshtime. </p>

                            <p style={{fontSize: "var(--texte-size-petit)"}}>Avant tout, validons votre ou vos appareils : </p>

                            <div className={"mesures"}>
                                {capteurs?.map(capteur => {
                                    return (
                                        <Carte_capteur capteur={capteur} key={capteur.uuid}/>
                                    );
                                })}
                                {hubs?.map(hub => {
                                    return (
                                        <Carte_hub hub={hub} key={hub.uuid}/>
                                    );
                                })}


                                <Modale_ajouter_appareil handleValiderAppareil={(type_appareil, ref, nom) => {

                                    return new Promise((resolve, reject) => {

                                        if (capteurs.filter(c => c.uuid === ref).length > 0) {
                                            alert("Vous ne pouvez pas ajouter deux fois le même appareil.")
                                            resolve(false)
                                            return;
                                        }
                                        if (hubs.filter(c => c.uuid === ref).length > 0) {
                                            alert("Vous ne pouvez pas ajouter deux fois le même appareil.")
                                            resolve(false)
                                            return;
                                        }

                                        ajax.get("/inscription/check_appareil/" + ref).then(res => {
                                            if (res.data.capteur?.uuid === ref)
                                            {
                                                setCapteurs([...capteurs, {
                                                    configuration_hash: 0,
                                                    creation: "",
                                                    error_code: 0,
                                                    niveau_batterie: null,
                                                    nom: nom,
                                                    notes: "",
                                                    hub: {
                                                        derniere_connexion: new DateTZ().toISOString(),
                                                        heartbeat: new DateTZ().toISOString(),
                                                        modele: "",
                                                        nom: "",
                                                        notes: "",
                                                        ref: "",
                                                        signal_gsm: "0",
                                                        uuid: ref,
                                                        version_logicielle: ""
                                                    },
                                                    produit: {
                                                        id: 0, nom: res.data.capteur?.modele , ref: ""
                                                    },
                                                    rssi: null,
                                                    snr: null,
                                                    numero_serie: "",
                                                    ref: ref,
                                                    uuid: ref
                                                }])
                                                resolve(true)
                                                return;
                                            }
                                            else if (res.data.hub?.uuid === ref)
                                            {
                                                setHubs([...hubs, {
                                                    derniere_connexion: new DateTZ().toISOString(),
                                                    heartbeat: new DateTZ().toISOString(),
                                                    modele: res.data.hub?.modele,
                                                    nom: nom,
                                                    notes: "",
                                                    ref: ref,
                                                    signal_gsm: "0",
                                                    uuid: ref,
                                                    version_logicielle: ""
                                                }])
                                                resolve(true)
                                                return;
                                            }
                                            else{
                                                alert("Aucun appareil ne correspond à cet référence. ")
                                                resolve(false)
                                                return;
                                            }
                                        }).catch(e => {
                                            resolve(false)
                                        })
                                    })
                                }}/>

                            </div>

                            {capteurs.length > 0 && (
                                <div>
                                    <p>Merci, vous pouvez ajouter d'autres appareils ou continuer la création de votre organisation : </p>
                                    {/*<button className={"big"} onClick={prevStep}>Retour</button>*/}
                                    <br />
                                    <button className={"big"} style={{padding: "1rem"}} onClick={nextStep}>Continuer <IconeRight /></button>
                                </div>
                            )}


                        </div>
                    </Stepper.Step>



                    <Stepper.Step label="Création de l'organisation">
                        <div className={"stepper_inscription-page"}>
                            <p>Sur la plateforme Freshtime, votre organisation centralise toutes vos données. </p>
                            <p>Elle représente généralement votre société.</p>
                            <p>C'est à travers votre organisation que vous pourrez configurer vos appareils, gérer vos utilisateurs et consulter vos mesures.</p>
                            <p>Pour pouvoir créer votre organisation et y intégrer vos appareils, nous avons besoin du nom de celle-ci : </p>
                            <Input
                                placeholder={"Le nom de votre société"}
                                value={nom_organisation}
                                onChange={e => set_nom_organisation(e.target.value)}
                            />


                            {nom_organisation.length > 0 && (
                                <div>
                                    <p>Merci, votre organisation sera nommée <strong>{nom_organisation}</strong></p>
                                    {/*<button className={"big"} onClick={prevStep}>Retour</button>*/}
                                    <br />
                                    <button className={"big"} style={{padding: "1rem"}} onClick={nextStep}>Continuer <IconeRight /></button>
                                </div>
                            )}
                        </div>
                    </Stepper.Step>



                    <Stepper.Step label="Enregistrement de l'email">
                        <div className={"stepper_inscription-page"}>
                            <p>Merci, nous allons maintenant créer votre compte et le lier à votre nouvelle organisation.</p>
                            <p>Pour commencer, nous avons juste besoin de votre adresse e-mail : </p>
                            <Input
                                placeholder={"email@business.com"}
                                value={email}
                                onChange={e => set_email(e.target.value)}
                                // leftSection={<IconeAt />}
                            />

                            <p>Vous allez recevoir un email pour finaliser votre compte et choisir votre mot de passe.</p>
                            <p>Une fois votre compte finalisé, vous pourrez directement vous connecter pour finaliser la configuration de {capteurs.length + hubs.length > 1 ? "vos appareils" : "votre appareil"}.</p>


                            {(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email)) && (
                                <div>
                                    {/*<button className={"big"} onClick={prevStep}>Retour</button>*/}
                                    <br />
                                    <Checkbox
                                        label={<>J'ai lu les <a href={env_config.url_cg_vente} target="_blank">conditions générales de ventes</a> et j'y adhère sans réserve.</>}
                                        checked={cgv}
                                        onChange={e => setCgv(e.target.checked)}
                                    />
                                    <br />
                                    {/*<Checkbox*/}
                                    {/*    label={<>J'ai lu les <a href={env_config.url_cg_utilisation} target="_blank">conditions générales d'utilisation</a> et j'y adhère sans réserve.</>}*/}
                                    {/*    checked={cgu}*/}
                                    {/*    onChange={e => setCgu(e.target.checked)}*/}
                                    {/*/>*/}
                                    <br />
                                    <Loading_button className={"big"}
                                                    style={{padding: "1rem"}}
                                                    onClick={handleValidation}
                                                    is_loading={btn_loading}
                                                    disabled={!cgv}
                                    >Valider mon email et créer mon organisation <IconeCheck /></Loading_button>
                                </div>
                            )}
                        </div>
                    </Stepper.Step>



                    <Stepper.Completed>
                        <div className={"stepper_inscription-page"}>
                            <p>Votre organisation a bien été créée.</p>
                            <h3>Merci, la suite se passe dans votre boite email.</h3>
                            <p>L'email peut mettre quelques minutes à arriver.</p>
                            <p></p>
                        </div>
                    </Stepper.Completed>
                </Stepper>








            </div>
        </div>

    );
}
